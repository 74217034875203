import React from "react";
import { WidgetDto } from "../types";
import { FormSchemaForm } from "../../components/FormSchemaForm";
import { API } from "../../client/API";

export const FormPlugin: React.FC<
  WidgetDto & { setElementRef: (el: HTMLDivElement) => void }
> = ({ data, setElementRef }) => {
  const handleSubmit = async (values: any) => {
    const { submitUrl, submitMethod, eventName } = data.form;
    if (submitUrl && submitMethod) {
      await API[submitMethod.toLowerCase()](submitUrl, values).then(
        (results) => {
          window.dispatchEvent(new CustomEvent(eventName, { detail: results }));
        }
      );
    }
  };

  return (
    <div ref={setElementRef} className={data.className}>
      {data.form && (
        <FormSchemaForm
          form={data.form}
          onSubmit={handleSubmit}
          initialValues={{}}
        />
      )}
    </div>
  );
};
