import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { PageElementDto } from "../../../dtos";
import { usePageEditor } from "../PageStore/PageEditorProvider";
import { ElementToolbar } from "./ElementToolbar/ElementToolbar";
import { getWidgetConfig } from "../../../widgets";
import { ElementOverlay } from "./ElementOverlay/ElementOverlay";
import { useHistory } from "react-router-dom";
import { dispatchEditElementEvt } from "../PageEditorPanel/RouteProvider";

export const ElementContext = createContext<Record<string, any>>({});

interface PageElementProps {
  element: PageElementDto;
}

const useElementClickListener = (el: HTMLElement, elementId: string) => {
  const history = useHistory();

  useEffect(() => {
    if (!el || !elementId) {
      return () => null;
    }

    const handleClick = (e: any) => {
      e.stopPropagation();
      dispatchEditElementEvt(elementId);
    };

    el.addEventListener("click", handleClick, false);

    return () => {
      el.removeEventListener("click", handleClick, false);
    };
  }, [history.location, el, elementId]);
};

export const ElementProvider: FC<PageElementProps> = ({
  element,
  children,
}) => {
  const { focusedId, hoveredId } = usePageEditor();
  const [elementRef, setElementRef] = useState<HTMLDivElement>(null);
  const isFocused = element.id === focusedId;
  const isHovered = element.id === hoveredId;
  const pluginConfig = getWidgetConfig(element);

  useElementClickListener(elementRef, element.id);

  return (
    <ElementContext.Provider
      value={{
        isFocused,
        isHovered,
        element,
        pluginConfig,
        elementRef,
        setElementRef,
      }}
    >
      {children}
      {/* <ElementToolbar /> */}
      <ElementOverlay />
    </ElementContext.Provider>
  );
};

export const useElement = () => {
  const context = useContext(ElementContext);

  return context;
};
