import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
} from "react";
import { PagesDto } from "../../../dtos";
import { usePageEditorProvider } from "./usePageEditorProvider";

const PageEditorContext = createContext<ReturnType<
  typeof usePageEditorProvider
> | null>(null);

type PageProviderProps = {
  page: PagesDto;
  setPage: Dispatch<SetStateAction<PagesDto>>;
};

export const PageProvider: FC<PageProviderProps> = ({
  page,
  setPage,
  children,
}) => {
  const pageStore = usePageEditorProvider(page, setPage);

  return (
    <PageEditorContext.Provider value={pageStore as any}>
      {children}
    </PageEditorContext.Provider>
  );
};

export const usePageEditor = () => {
  const context = useContext(PageEditorContext);

  if (!context) {
    //   throw new Error("You must be logged in to edit");
  }

  return context;
};
