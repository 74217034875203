import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import deepEqual from "deep-equal";
import { PageElementDto, PagesDto } from "../../../dtos";
import {
  setElementData as setElementPageData,
  duplicateElementOnPage,
  getElementData,
  removeElementFromPage,
  updatePageElement,
  addWidgetToElement,
  findElementDeep,
} from "./pageUtils";
import { API } from "../../../client/API";
import useStateWithHistory from "../../../hooks/useStateHistory";
import { PageElement } from "../PageElement/PageElement";
import { useFormSchema } from "../../../widgets/hooks/useFormSchema";

export function usePageEditorProvider(
  passedPage: PagesDto,
  setPassedPage: Dispatch<SetStateAction<PagesDto>>
) {
  const [saveStatus, setSaveStatus] = useState<
    "LOADING" | "ERROR" | "COMPLETE"
  >();
  const [mode, setMode] = useState<
    "RESIZE" | "EDIT" | "MOVE" | "FOCUSED" | "PREVIEW"
  >("EDIT");
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const [focusedId, setFocusedId] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<Error>();
  const [page, setPage, meta] = useStateWithHistory<PagesDto>(
    passedPage,
    setPassedPage
  );
  const { formSchema: pageElementSchema } = useFormSchema("page-element");

  useEffect(() => {
    if (!deepEqual(page, passedPage)) {
      setIsDirty(true);
    }
  }, [page]);

  const setElementData = (id, data: object = {}) => {
    return setPage({
      ...page,
      elements: setElementPageData(id, data, page.elements as PageElementDto[]),
    });
  };

  const setPageCSS = (css: string) => {
    return setPage({ ...page, customCss: css });
  };

  const updateElement = (id: string, element: PageElementDto) => {
    return setPage({
      ...page,
      elements: updatePageElement(
        id,
        element,
        page.elements as PageElementDto[]
      ),
    });
  };

  const removeElement = (id: string) => {
    return setPage({
      ...page,
      elements: removeElementFromPage(id, page.elements as PageElementDto[]),
    });
  };

  const duplicateElement = (id: string) => {
    return setPage({
      ...page,
      elements: duplicateElementOnPage(id, page.elements as PageElementDto[]),
    });
  };

  const addElementToPage = (id: string, newElement: PageElementDto) => {
    return setPage({
      ...page,
      elements: addWidgetToElement(
        id,
        newElement,
        page.elements as PageElementDto[]
      ),
    });
  };

  const getElement = useCallback(
    (id: string) => {
      return findElementDeep(id, page.elements as PageElementDto[]);
    },
    [page.elements]
  );

  const savePage = useCallback(() => {
    if (saveStatus) {
      return;
    }
    setSaveStatus("LOADING");
    return API.put(`/pages/${page._id}`, page)
      .then((savedPage) => {
        setSaveStatus("COMPLETE");
        setPage(savedPage);
        setSaveError(null);
        setFocusedId(null);
        setMode("PREVIEW");
      })
      .catch((err) => {
        setSaveStatus("ERROR");
        setSaveError(err);
      })
      .finally(() => {
        setTimeout(() => {
          setSaveStatus(null);
        }, 500);
      });
  }, [page, saveStatus]);

  return {
    page,
    saveStatus,
    saveError,
    revisions: meta.history,
    revisionNumber: meta.pointer,
    isDirty,
    focusedId,
    hoveredId,
    mode,
    pageElementSchema,
    setMode,
    setFocusedId,
    setPage,
    setPageCSS,
    setHoveredId,
    getElement,
    setElementData,
    removeElement,
    duplicateElement,
    updateElement,
    savePage,
    addElementToPage,
    goForward: meta.goForward,
    goBack: meta.goBack,
  };
}
