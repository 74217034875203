import React from "react";
import { WidgetDto } from "../types";

export const DefaultPlugin: React.FC<WidgetDto> = ({ type, ...rest }) => {
  return (
    <div>
      <b>The plugin {type} does not exist</b>
      {/* Component logic goes here */}
      <pre>{JSON.stringify(rest, null, 2)}</pre>
    </div>
  );
};
