import { useState } from "react";

const useToggle = (
  initialValue?: boolean
): [boolean, () => void, () => void, () => void] => {
  const [isValueTrue, setIsValueTrue] = useState<boolean>(!!initialValue);

  const toggle = () => {
    setIsValueTrue((currentValue) => !currentValue);
  };

  const setTrue = () => {
    setIsValueTrue(true);
  };

  const setFalse = () => {
    setIsValueTrue(false);
  };

  return [isValueTrue, toggle, setTrue, setFalse];
};

export default useToggle;
