import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useIsAdmin } from "../../../hooks/useIsAdmin";

const ReadOnlyContext = createContext<boolean>(true);

const ENTER_EDIT_MODE = "enter_edit_mode";
const LEAVE_EDIT_MODE = "leave_edit_mode";
const EDIT_MODE_EVENTS = [ENTER_EDIT_MODE, LEAVE_EDIT_MODE];

export const enterEditMode = () => {
  window.dispatchEvent(new CustomEvent(ENTER_EDIT_MODE));
};

export const leaveEditMode = () => {
  window.dispatchEvent(new CustomEvent(LEAVE_EDIT_MODE));
};

const setupWindowListeners = (setIsReadOnly: any) => {
  const handleEvt = (e: CustomEvent) => {
    setIsReadOnly(e.type === LEAVE_EDIT_MODE);
  };

  EDIT_MODE_EVENTS.forEach((evt) => {
    window.addEventListener(evt, handleEvt, false);
  });

  return () => {
    EDIT_MODE_EVENTS.forEach((evt) => {
      window.removeEventListener(evt, handleEvt, false);
    });
  };
};

export const ReadOnlyProvider: FC = ({ children }) => {
  const isAdmin = useIsAdmin();
  const [isReadOnly, setIsReadOnly] = useLocalStorage<boolean>(
    "EDIT_MODE",
    true
  );

  useEffect(() => {
    return setupWindowListeners(setIsReadOnly);
  }, []);

  const isReadOnlyValue = useMemo(() => {
    if (!isAdmin) {
      return true;
    }
    return isReadOnly;
  }, [isReadOnly, isAdmin]);

  return (
    <ReadOnlyContext.Provider value={isReadOnlyValue}>
      {children}
    </ReadOnlyContext.Provider>
  );
};

export const useIsReadOnly = () => useContext(ReadOnlyContext);
