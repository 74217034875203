import React, { useEffect } from "react";
import loadable from "@loadable/component";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useDoty } from "./useDoty";
import { Box, CircularProgress } from "@mui/material";
import SiteMeta from "../../components/SiteMeta";
import { useIsAppHeaderSticky } from "./useAppHeaderSticky";
import { FormDialog } from "../../components/FormDialog/FormDialog";
import useToggle from "../../hooks/useToggle";
import { useIsEditingAllowed } from "../../hooks/useIsEditingAllowed";
import { USER_ROLES } from "../../dtos";

const DotySingleDesktop = loadable(() => import("./DotySingleDesktop"));
const DotySingleMobile = loadable(() => import("./DotySingleMobile"));

export const DotySingle = () => {
  const isMobile = useIsMobile();
  const isSticky = useIsAppHeaderSticky();
  const { doty, setDoty, dotyId } = useDoty();
  const [isEditing, toggleIsEditing] = useToggle(false);
  const isEditingAllowed = useIsEditingAllowed(doty.createdBy, [
    USER_ROLES.ADMIN,
    USER_ROLES.EDITOR,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isMobile) {
      setTimeout(() => {
        window.scrollTo(0, 1);
      }, 10);
    }
  }, [isMobile, dotyId]);

  const JSX = isMobile ? (
    <DotySingleMobile
      doty={doty}
      setDoty={setDoty}
      isAppHeaderSticky={isSticky}
      isEditingAllowed={isEditingAllowed}
      toggleIsEditing={toggleIsEditing}
    />
  ) : (
    <DotySingleDesktop
      doty={doty}
      setDoty={setDoty}
      isAppHeaderSticky={isSticky}
      isEditingAllowed={isEditingAllowed}
      toggleIsEditing={toggleIsEditing}
    />
  );

  const JSXContent = !!doty ? JSX : <CircularProgress />;

  return (
    <Box minHeight="100%" bgcolor="#222" id={doty?._id}>
      <SiteMeta
        title={doty?.fullName}
        description={doty?.description}
        image={doty?.images[0]}
      />
      {JSXContent}
      {isEditingAllowed && (
        <FormDialog
          initialValues={doty}
          isOpen={isEditing}
          onClose={toggleIsEditing}
          onSubmitSuccess={setDoty}
          formSlug="dotycandidates"
        />
      )}
    </Box>
  );
};

DotySingle.displayName = "DotySingle";
