import React from "react";
import { UploadsDto } from "../../dtos";
import { Avatar, AvatarProps } from "@mui/material";
import { getImageProps } from "../../utils/getImageProps";

type Props = {
  fullName: String;
  profilePicture?: UploadsDto;
} & AvatarProps;

export const CustomAvatar: React.FC<Props> = ({
  fullName,
  profilePicture,
  onClick,
  className,
  sx = {},
}) => {
  if (!fullName && !profilePicture) {
    return null;
  }

  const imageProps =
    typeof profilePicture === "object" ? getImageProps(profilePicture) : null;

  if (imageProps) {
    return (
      <Avatar
        className={className}
        onClick={onClick}
        {...imageProps}
        alt={fullName}
        sx={sx}
      />
    );
  }

  const [firstName, lastName] = fullName.split(" ");
  const initials = (firstName || "")[0] + (lastName || "")[0];

  return (
    <Avatar
      onClick={onClick}
      className={className}
      sx={{ backgroundColor: "#f5f5f5", color: "#333", ...sx }}
    >
      {initials}
    </Avatar>
  );
};
