import clsx from "clsx";
import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "../../pages";

type Props = {
  className: string;
};

const getRouteKey = (path: string | string[]) =>
  Array.isArray(path) ? path.join("-") : path;

export function AppRoutes({ className }: Props) {
  return (
    <div id="APP_ROUTES" className={clsx("app-routes", className)}>
      <Switch>
        {APP_ROUTES.map((route) => {
          return (
            <Route
              key={getRouteKey(route.path)}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          );
        })}
      </Switch>
    </div>
  );
}
