import { CtaListPlugin } from "./CtaListPlugin";
import { CtaListEditor } from "./CtaListEditor";
import { PluginConfig } from "../types";

const CtaListPluginConfig: PluginConfig = {
  name: "CtaList",
  Component: CtaListPlugin,
  Editor: CtaListEditor,
  allowsChildren: false,
};

export default CtaListPluginConfig;
