import { PageElementDto } from "../dtos";
import BannerPluginConfig from "./Banner";
import CardPluginConfig from "./Card";
import CellPluginConfig from "./Cell";
import CtaListPluginConfig from "./CtaList";
import DefaultPluginConfig from "./Default";
import DotyListPluginConfig from "./DotyList";
import FormPluginConfig from "./Form";
import FormDialogPluginConfig from "./FormDialog";
import ImagePluginConfig from "./Image";
import JoshPluginConfig from "./Josh";
import RowPluginConfig from "./Row";
import TextPluginConfig from "./Text";
import YoutubePluginConfig from "./Youtube";

const PAGE_ELEMENTS = [
  DefaultPluginConfig,
  ImagePluginConfig,
  YoutubePluginConfig,
  BannerPluginConfig,
  TextPluginConfig,
  CardPluginConfig,
  CtaListPluginConfig,
  RowPluginConfig,
  CellPluginConfig,
  JoshPluginConfig,
  FormPluginConfig,
  DotyListPluginConfig,
  FormDialogPluginConfig,
];

export const isRow = (element: PageElementDto) => {
  return [element.type, element.elementType].some(
    (s) => s?.toLowerCase() === "row"
  );
};

export const getWidgetConfig = (element: PageElementDto) => {
  if (!element) {
    return undefined;
  }
  if (isRow(element)) {
    return RowPluginConfig;
  }

  return PAGE_ELEMENTS.find((pluginConfig) => {
    return pluginConfig?.name?.toLowerCase() === element.type?.toLowerCase();
  });
};

export default PAGE_ELEMENTS;
