function getVisiblePercentage(
  element: HTMLElement,
  containerEl?: HTMLElement
): number {
  // Get element's and container's bounding rectangles
  const elementRect = element.getBoundingClientRect();
  const containerRect = containerEl.getBoundingClientRect();

  // Get container's scroll position
  const scrollTop = containerEl.scrollTop;
  const scrollLeft = containerEl.scrollLeft;

  // Calculate element's position relative to container
  const relativeRect = {
    top: elementRect.top - containerRect.top + scrollTop,
    left: elementRect.left - containerRect.left + scrollLeft,
    bottom: elementRect.bottom - containerRect.top + scrollTop,
    right: elementRect.right - containerRect.left + scrollLeft,
    width: elementRect.width,
    height: elementRect.height,
  };

  // Calculate visible coordinates within container
  const visibleLeft = Math.max(0, relativeRect.left);
  const visibleRight = Math.min(containerEl.clientWidth, relativeRect.right);
  const visibleTop = Math.max(0, relativeRect.top);
  const visibleBottom = Math.min(containerEl.clientHeight, relativeRect.bottom);

  // Calculate areas
  const elementArea = elementRect.width * elementRect.height;

  // If element is completely outside container's visible area, return 0
  if (
    relativeRect.left > containerEl.clientWidth ||
    relativeRect.right < 0 ||
    relativeRect.top > containerEl.clientHeight ||
    relativeRect.bottom < 0
  ) {
    return 0;
  }

  // Calculate visible area
  const visibleWidth = visibleRight - visibleLeft;
  const visibleHeight = visibleBottom - visibleTop;
  const visibleArea = visibleWidth * visibleHeight;

  // Calculate and return percentage (0 to 100)
  const percentage = Math.min(
    100,
    Math.max(0, (visibleArea / elementArea) * 100)
  );

  if (percentage < 0.1) {
    return 0;
  }
  if (percentage > 99.5) {
    return 100;
  }

  return percentage;
}

export type VisiblePercentageConfig = {
  idx: number;
  percentage: number;
  isVisible: boolean;
  id: string;
};

export const getVisiblePercentages = (
  elementsMap: Record<string, HTMLElement>,
  containerElement?: HTMLElement
): VisiblePercentageConfig[] => {
  return Object.entries(elementsMap)
    .map(([id, element]) => {
      let percentage = getVisiblePercentage(element, containerElement);
      const idx = parseInt(element.dataset.imageIdx, 10);
      const isVisible = percentage > 0;

      if (percentage < 0.1) {
        percentage = 0;
      }

      return {
        idx,
        percentage,
        isVisible,
        id,
      };
    })
    .sort((a, b) => b.percentage - a.percentage);
};
