import { useState, useEffect, useCallback } from "react";
import { API } from "../../client/API";
import { FormDto } from "../../dtos";

type UseFormSchemaReturnType = {
  isLoading: boolean;
  error?: Error;
  formSchema?: FormDto;
  fetchFormSchema?: () => void;
};

export function useFormSchema(
  formSlug: string,
  shouldRequestOnMount = true
): UseFormSchemaReturnType {
  const [isLoading, setIsLoading] = useState<boolean>(shouldRequestOnMount);
  const [formSchema, setFormSchema] = useState<FormDto>();
  const [error, setError] = useState<Error>();

  const fetchFormSchema = useCallback(() => {
    setIsLoading(true);
    API.get<FormDto>(`/forms/${formSlug}`)
      .then((schema) => {
        setFormSchema(schema);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  }, [formSlug]);

  useEffect(() => {
    if (shouldRequestOnMount) {
      fetchFormSchema();
    }
  }, [fetchFormSchema, shouldRequestOnMount]);

  return { isLoading, formSchema, error, fetchFormSchema };
}
