import React from "react";
import { useEffect } from "react";
import { API } from "../../client/API";
import { useUser } from "../../providers/UserProvider";

const GOOGLE_CLIENT_ID =
  "694586586213-s15kuom1593eeu2u0i9qkn6n90m36dli.apps.googleusercontent.com";

const GoogleAuth = () => {
  const { setUser } = useUser();
  useEffect(() => {
    // Load Google API script
    const loadGoogleScript = () => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        try {
          (window as any).google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: handleGoogleResponse,
          });

          (window as any).google.accounts.id.renderButton(
            document.getElementById("google-signin"),
            { theme: "outline", size: "large" }
          );
        } catch (error) {
          console.error("Google initialization error:", error);
        }
      };

      return () => {
        document.body.removeChild(script);
      };
    };

    return loadGoogleScript();
  }, []);

  const handleGoogleResponse = async (response) => {
    try {
      const res = await API.post("/auth/google", {
        credential: response.credential,
      });
      setUser(res);
    } catch (error) {
      console.error("Google authentication error:", error);
    }
  };

  return (
    <div className="google-auth-container">
      <div id="google-signin"></div>
    </div>
  );
};

export default GoogleAuth;
