import React from "react";
import { WidgetDto } from "../types";

export const BannerPlugin: React.FC<WidgetDto> = (props) => {
  return (
    <div>
      {/* Component logic goes here */}
      {JSON.stringify(props, null, 2)}
    </div>
  );
};
