import { Box } from "@mui/material";
import React, { useMemo } from "react";

type Props = {
  currentIndex: number;
  totalImages: number;
};

const MAX_BULLETS = 5;

export const MobileGalleryIndicator: React.FC<Props> = ({
  currentIndex,
  totalImages,
}) => {
  const bulletProps = useMemo(() => {
    return Array.from({ length: totalImages }, (_, index) => ({
      key: index,
    }));
  }, [totalImages]);

  const bulletsToRender = useMemo(() => {
    if (bulletProps.length <= MAX_BULLETS) {
      return bulletProps;
    }
    if (currentIndex >= Math.floor(MAX_BULLETS / 2)) {
      let startSlice = currentIndex - Math.floor(MAX_BULLETS / 2);
      const endSlice = currentIndex + Math.ceil(MAX_BULLETS / 2);
      if (endSlice > bulletProps.length) {
        startSlice = startSlice - (endSlice - bulletProps.length);
      }
      return bulletProps.slice(startSlice, endSlice).slice(0, MAX_BULLETS);
    }
    return bulletProps.slice(0, MAX_BULLETS);
  }, [currentIndex, bulletProps]);

  return (
    <Box
      gap={0.65}
      pt={1}
      minHeight={24}
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      {bulletsToRender.map((bulletProp, idx) => {
        const isActive = bulletProp.key === currentIndex;
        const isFirst = bulletProp.key !== 0 && idx === 0;
        const isLast =
          bulletProp.key !== totalImages - 1 &&
          idx === bulletsToRender.length - 1;

        const isSmall = isFirst || (isLast && !isActive);
        const bulletWidth = isSmall ? 4 : 6;
        return (
          <Box
            key={bulletProp.key}
            border={isActive ? 2 : 1}
            borderColor={isActive ? "secondary.main" : "grey.300"}
            borderRadius={5}
            sx={{
              width: isActive ? 8 : bulletWidth,
              height: isActive ? 8 : bulletWidth,
              backgroundColor: isActive ? "secondary.main" : "grey.200",
              transition: "all ease-in-out .15s",
            }}
          />
        );
      })}
    </Box>
  );
};
