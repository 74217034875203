import React from "react";
import { WidgetDto } from "../types";
import { Box, Button } from "@mui/material";

export const CtaListPlugin: React.FC<WidgetDto> = ({ data = {} }) => {
  const { ctas = [], sx = {} } = data;
  if (!ctas.length) {
    return <b>No Ctas Present</b>;
  }
  return (
    <Box sx={sx}>
      {ctas.map((cta) => {
        const { ts, label, href, className, variant, color } = cta;
        return (
          <Button
            component="a"
            href={href}
            key={ts}
            color={color}
            variant={variant}
            className={className}
          >
            {label}
          </Button>
        );
      })}
    </Box>
  );
};
