import { BannerPlugin } from './BannerPlugin';
import { BannerEditor } from './BannerEditor';
import { PluginConfig } from '../types';

const BannerPluginConfig: PluginConfig = {
	name: "Banner",
	Component: BannerPlugin,
	Editor: BannerEditor,
}

export default BannerPluginConfig;