import { UploadFormatDto, UploadsDto } from "../dtos";

const getApiUrl = (src?: string) => {
  return src;
};

export enum SizeTypes {
  THUMB = "thumbnail",
  XSMALL = "xs",
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
  DEFAULT = "default",
}

const sizes = [
  // SizeTypes.THUMB,
  SizeTypes.XSMALL,
  SizeTypes.SMALL,
  SizeTypes.MEDIUM,
  SizeTypes.LARGE,
];

export const getSrcSet = (formats: any = {}) => {
  return sizes
    .reduce((filterMap: string[], s) => {
      const format: any = formats[s];

      if (format) {
        filterMap.push(`${format.url} ${format.width}w`);
      }
      return filterMap;
    }, [])
    .join();
};

// const IMAGE_FORMATS = [
//   { name: "thumbnail", width: 125, height: 125 },
//   { name: "xs", width: 320 },
//   { name: "sm", width: 600 },
//   { name: "md", width: 900 },
//   { mame: "lg", width: 1536 },
// ];

const getSizesAttribute = (formats: Record<SizeTypes, UploadFormatDto>) => {
  return sizes
    .filter((size) => !!formats[size])
    .map((size) => {
      const format: any = formats[size];

      //  sizes="(max-width: 480px) 320px, (max-width: 600px) 600px, 900px"
      if (format && size === SizeTypes.XSMALL) {
        return `(max-width: 480px) 320px`;
      }

      return `${format.width}px`;
    })
    .join(", ");
};

export function getImageProps(
  upload?: UploadsDto | string,
  size: SizeTypes | string = SizeTypes.DEFAULT
): any {
  if (!upload || typeof upload === "string") {
    return undefined;
  }
  if (typeof upload === "string") {
    return {
      src: upload,
      alt: upload,
    };
  }
  const { url, name, alternativeText, _id: id } = upload;
  const formats: any = upload.formats || {};
  const props = {
    alt: alternativeText || name,
    src: getApiUrl(url),
    srcSet: getSrcSet(formats),
    sizes: getSizesAttribute(formats),
    "data-asset-id": id,
    id,
  };
  if (size && formats[size]) {
    props.src = getApiUrl(formats[size].url);
  }
  return props;
}

export function getSmallestFormat(image: UploadsDto): string {
  const formats = image?.formats || {};
  const key = sizes.reverse().find((size) => !!formats[size]);
  if (key) {
    return image.formats[key].url;
  }
  return image.url;
}
