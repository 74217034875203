import React, {
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { DotyCandidatesDto } from "../../../../dtos";
import { Box, Button, Card, CardContent, CardHeader } from "@mui/material";
import { ScrollSnapGallery } from "../../../../components/ScrollSnapGallery/ScrollSnapGallery";
import { MobileGalleryIndicator } from "../../../../components/ScrollSnapGallery/MobileIndicator";
import useToggle from "../../../../hooks/useToggle";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import { CommentsList } from "../../../../components/CommentsList/CommentsList";
import { useDotyListProps } from "../../useDoty";
import SwipableBottomDrawer from "./SwipableBottomDrawer/SwipableBottomDrawer";
import { DotyContextMenu } from "../DotyContextMenu/DotyContextMenu";
import { useDotyVote } from "../../../../views/DotySingle/useDotyVote";
import { useLocation } from "react-router-dom";

type Props = {
  doty: DotyCandidatesDto;
  setDoty: (doty: DotyCandidatesDto) => void;
  handleItemClick: (e: MouseEvent<HTMLElement>) => void;
};

const MAX_WORDS_LIMIT = 25;

export const DotyCardMobile: React.FC<Props> = ({
  doty: initialDoty,
  setDoty: setStateDoty,
  handleItemClick,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [doty, setDoty] = useState(initialDoty);
  const [isCommentsOpen, toggleCommentsOpen, openComments, closeComments] =
    useToggle(false);
  const [cardHeight, setCardHeight] = useState<number>(0);
  const handleVoteClick = useDotyVote({ doty, setDoty });
  const { state } = useLocation<Record<string, string>>();
  const prevDotyId = state?.prevDotyId;

  useLayoutEffect(() => {
    setCardHeight(window.innerHeight - 100);
  }, []);

  const [isDescOpen, toggleDesc] = useToggle();

  const { description } = doty;

  const cleanedDescription = useMemo<string>(() => {
    return description.split(" ").filter(Boolean).join(" ");
  }, [description]);

  const truncatedDescription = useMemo<string>(() => {
    return cleanedDescription.split(" ").slice(0, MAX_WORDS_LIMIT).join(" ");
  }, [cleanedDescription]);

  const isMoreLessToggle =
    truncatedDescription.length < cleanedDescription.length;

  useEffect(() => {
    setDoty(initialDoty);
  }, [initialDoty]);

  useEffect(() => {
    if (prevDotyId && doty._id === prevDotyId) {
      setTimeout(() => {
        cardRef.current?.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }, 0);
    }
  }, [doty._id, prevDotyId]);

  const handleCommentCountChange = (count: number) => {
    setStateDoty({ ...doty, commentsCount: count });
  };

  return (
    <>
      <Card
        ref={cardRef}
        sx={{
          position: "relative",
          height: {
            xs: cardHeight || "calc(100vh - 100px)",
            sm: "100%",
          },
          display: "flex",
          flexDirection: "column",
        }}
        onDoubleClick={handleVoteClick}
      >
        <CardHeader
          titleTypographyProps={{
            sx: {
              fontSize: "1rem",
              fontWeight: 600,
            },
          }}
          sx={{ py: 1, px: 2 }}
          title={
            <Box
              href={`/doty/${doty._id}`}
              onClick={handleItemClick}
              color="#fff"
              component="a"
              sx={{ textDecoration: "none" }}
            >
              {doty.fullName}
            </Box>
          }
          action={<DotyContextMenu doty={doty} />}
        />
        <ScrollSnapGallery
          sx={{ flex: { xs: 1, sm: "0 0 auto" }, overflow: "hidden" }}
          maxHeightXs="100%"
          maxHeightSm={350}
          isMobile
          disableKeyboardEvents
          disableLightboxGallery
          images={doty.images}
          onIndexChange={setCurrentIndex}
          isPagingHidden
        />
        <MobileGalleryIndicator
          currentIndex={currentIndex}
          totalImages={doty.images.length}
        />
        <Box px={1.5} gap={1} display="flex">
          <Button
            size="small"
            sx={{
              fontSize: "0.85rem",
              whiteSpace: "nowrap",
            }}
            startIcon={
              doty.hasVoted ? (
                <FavoriteIcon sx={{ fontSize: "20px !important" }} />
              ) : (
                <FavoriteBorderIcon sx={{ fontSize: "20px !important" }} />
              )
            }
            onClick={handleVoteClick}
          >
            {doty.votesCount} Votes
          </Button>
          <Button
            sx={{
              fontSize: "0.85rem",
              whiteSpace: "nowrap",
            }}
            onClick={openComments}
            startIcon={
              <ChatBubbleOutlineIcon sx={{ fontSize: "19px !important" }} />
            }
          >
            {doty.commentsCount} Comments
          </Button>
        </Box>
        <CardContent
          sx={{
            fontSize: "0.85rem",
            flex: { xs: "0 0 auto", sm: 1 },
            py: 0,
            px: 2,
            pb: 1,
          }}
          onClick={isMoreLessToggle ? toggleDesc : undefined}
        >
          {isDescOpen ? cleanedDescription : truncatedDescription}
          {(isMoreLessToggle || isDescOpen) && (
            <Box component="span" ml={0.5} color="secondary.main">
              {isDescOpen ? "...less" : "...more"}
            </Box>
          )}
        </CardContent>
      </Card>
      <SwipableBottomDrawer isOpen={isCommentsOpen} onClose={closeComments}>
        <CommentsList
          referenceId={doty._id}
          numberOfComments={doty.commentsCount}
          onCommentsCountChange={handleCommentCountChange}
          isMobile
        />
      </SwipableBottomDrawer>
    </>
  );
};
