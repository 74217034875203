import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { CommentsDto, ReplyDto } from "../../../dtos";
import { API } from "../../../client/API";
import { useUser } from "../../../providers/UserProvider";
import {
  addChildToElement,
  convertModelsToDtos,
  findElementDeep,
  flattenElements,
  removeElementDeep,
  updateElementDeep,
} from "../../../utils/recursiveUtils";
import { generateId } from "../../../utils/generateId";

type Props = {
  referenceId: string;
  onModel: string;
};

export const useCommentsProvider = ({ referenceId, onModel }: Props) => {
  const [comments, setComments] = React.useState<CommentsDto[]>([]);
  const { user } = useUser();
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    setIsLoading(true);
    API.get(`/comments/${onModel}/${referenceId}`).then((results) => {
      setIsLoading(false);
      setComments(convertModelsToDtos(results, "replies"));
    });
  }, [referenceId, onModel]);

  const addComment = useCallback(
    async (comment: string, parentId: string, ancestorIds: string[]) => {
      const newComment = { comment, user, referenceId, onModel };
      if (!parentId) {
        await API.post(`/comments`, newComment).then((response) => {
          setComments((prevComments) => [
            { ...response, id: response._id },
            ...prevComments,
          ]);
        });
        return;
      } else {
        const newReply = {
          comment,
          user,
          replyingTo: parentId,
          ts: Date.now(),
        };
        const topLevelId = ancestorIds[0];
        const topLevelComment = comments.find(
          (c) => c._id === topLevelId || c._id === parentId
        );
        const parentIndex = topLevelComment.replies.findIndex(
          (c) => c._id === parentId
        );

        if (parentIndex > -1) {
          const updatedReplies = [...(topLevelComment.replies || [])];
          updatedReplies.splice(parentIndex + 1, 0, newReply as any);
          const updatedComment = {
            ...topLevelComment,
            replies: updatedReplies,
          };
          await API.put(`/comments/${topLevelId}`, updatedComment).then(
            (result) => {
              setComments((prevComments) =>
                prevComments.map((c) =>
                  c._id === result._id ? { ...result, id: result._id } : c
                )
              );
            }
          );
        } else {
          const updatedComment = {
            ...topLevelComment,
            replies: [newReply, ...topLevelComment.replies],
          };
          await API.put(`/comments/${topLevelId}`, updatedComment).then(
            (result) => {
              setComments((prevComments) =>
                prevComments.map((c) =>
                  c._id === result._id ? { ...result, id: result._id } : c
                )
              );
            }
          );
        }
      }
    },
    [comments]
  );

  const removeComment = useCallback(
    async (commentId: string, ancestorIds: string[]) => {
      const newComments = removeElementDeep(
        comments as any,
        commentId,
        "replies" as any
      );
      setComments(newComments as any);

      if (ancestorIds.length) {
        const parentComment = findElementDeep(
          newComments,
          ancestorIds[0],
          "replies" as any
        ) as any;
        await API.put(`/comments/${parentComment._id}`, parentComment);
      } else {
        await API.delete(`/comments/${commentId}`);
      }
    },
    [comments]
  );

  const updateComment = useCallback(
    async (comment: CommentsDto | ReplyDto, ancestorIds?: string[]) => {
      const newComments = updateElementDeep(
        comment as any,
        comments,
        "replies"
      );
      setComments(newComments);
      if (ancestorIds.length) {
        const parentComment = findElementDeep(
          newComments,
          ancestorIds[0],
          "replies"
        ) as any;
        await API.put(`/comments/${parentComment._id}`, parentComment);
      } else {
        await API.put(`/comments/${comment._id}`, comment);
      }
    },
    [comments]
  );

  const flattenedComments = useMemo(() => {
    return flattenElements(comments as any, "replies" as any);
  }, [comments]);

  return {
    isLoading,
    comments,
    addComment,
    removeComment,
    updateComment,
    flattenedComments,
    setComments,
  };
};

type CommentsContextProps = ReturnType<typeof useCommentsProvider>;

export const CommentsContext = React.createContext<
  CommentsContextProps | undefined
>(undefined);

export const useCommentsContext = () => {
  const context = useContext(CommentsContext);

  if (!context) {
    throw new Error("CommentsContext not set up");
  }

  return context;
};
