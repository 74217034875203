import { Grid, SxProps, Theme, useMediaQuery } from "@mui/material";
import React, { useState, useCallback, useRef } from "react";
import { usePageEditor } from "../../pages/Page/PageStore/PageEditorProvider";
import { useElement } from "../../pages/Page/PageElement/PageElementProvider";
import "./ResizableCell.scss";
import clsx from "clsx";
import { useBreakPoint } from "../../hooks/useBreakpoint";

interface ResizableProps {
  minWidth?: number;
  maxWidth?: number;
  flex?: string;
  initialWidth?: number;
  xs?: number;
  md?: number;
  lg?: number;
  xl?: number;
  sm?: number;
  id?: string;
  sx?: SxProps;
}

export const ResizableCell: React.FC<ResizableProps> = ({
  //   minSize = 1,
  //   maxSize = 12,
  sm = 12,
  xs,
  md,
  lg,
  xl,
  children,
  id,
  sx = {},
  flex,
}) => {
  const breakpoint = useBreakPoint();
  const { element, setElementRef } = useElement();
  const { updateElement, setMode } = usePageEditor();
  const cellRef = useRef<HTMLDivElement>();
  const [size, setSize] = useState<number>(sm);
  const [startWidth, setStartWidth] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);

  const startResizing = useCallback((mouseDownEvent: React.MouseEvent) => {
    setStartX(mouseDownEvent.clientX);
    setStartWidth(cellRef.current.offsetWidth);
    setIsResizing(true);
    setMode("RESIZE");
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
    const parentWidth = cellRef.current.parentElement.offsetWidth;
    const newSize = Math.ceil((width / parentWidth) * 12);

    updateElement(element.id, {
      ...element,
      data: {
        ...element.data,
        grid: {
          ...element.data.grid,
          [breakpoint]: newSize,
        },
      },
      size: newSize,
    });
    setMode("EDIT");
  }, [width]);

  const resize = useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing) {
        const parentWidth = cellRef.current.parentElement.offsetWidth;
        const currentWidth =
          cellRef.current.offsetWidth + mouseMoveEvent.clientX - startX;

        if (
          currentWidth >= (1 / 12) * parentWidth &&
          currentWidth <= parentWidth
        ) {
          setWidth(currentWidth);
          setStartX(mouseMoveEvent.clientX);
        }
      }
    },
    [isResizing, startX, size]
  );

  React.useEffect(() => {
    setStartWidth(cellRef.current.offsetWidth);
    setElementRef(cellRef.current);
  }, []);

  React.useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", resize);
      window.addEventListener("mouseup", stopResizing);
    }

    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [isResizing, resize, stopResizing]);

  const getCellProps = () => {
    if (isResizing) {
      return {
        style: { width: width || startWidth },
      };
    } else {
      return {
        sm,
        xs: 12,
        md,
        lg,
        xl,
      };
    }
  };

  const getIndicatorText = () => {
    const parentWidth = cellRef.current.parentElement?.offsetWidth;
    if (parentWidth) {
      const newSize = Math.ceil((width / parentWidth) * 12);
      return newSize;
    }
    return size;
  };

  return (
    <Grid
      ref={cellRef}
      item
      className="cell-wrapper"
      sx={{ ...sx, position: "relative" }}
      {...getCellProps()}
    >
      {children}
      {isResizing && <div className="indicator">{getIndicatorText()}</div>}
      <div
        className={clsx("drag-handle", isResizing && "is-resizing")}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "10px",
          height: "100%",
          cursor: "ew-resize",
        }}
        onMouseDown={startResizing}
      />
    </Grid>
  );
};
