import { YoutubePlugin } from './YoutubePlugin';
import { YoutubeEditor } from './YoutubeEditor';
import { PluginConfig } from '../types';

const YoutubePluginConfig: PluginConfig = {
	name: "Youtube",
	Component: YoutubePlugin,
	Editor: YoutubeEditor,
}

export default YoutubePluginConfig;