import React, { createContext, useContext } from "react";
import { usePagesProvider } from "./usePagesProvider";
import { Page } from "../Page";

type IPagesContext = ReturnType<typeof usePagesProvider>;

const PagesContext = createContext<IPagesContext | null>(null);

export const usePageContext = () => {
  const context = useContext(PagesContext);

  if (!context) {
    throw new Error("PagesContext was not setup");
  }

  return context;
};

export default function LazyPagesProvider() {
  const provider = usePagesProvider();

  return (
    <PagesContext.Provider value={provider}>
      <Page />
    </PagesContext.Provider>
  );
}
