import React, { Suspense, useEffect, useState } from "react";
import { WidgetDto } from "../types";
import { ReadOnlyEditor, RichTextEditor } from "./SlateEditor";
import { useIsReadOnly } from "../../pages/Page/ReadOnlyContext/ReadOnlyContext";
import { importEditorIcons } from "./utils";
import { Box } from "@mui/material";
import "./TextPlugin.scss";

export const TextPlugin: React.FC<WidgetDto> = ({ id, data = {} }) => {
  const { content } = data;
  const isReadOnly = useIsReadOnly();

  useEffect(() => {
    if (!isReadOnly) {
      importEditorIcons();
    }
  }, [isReadOnly]);

  return (
    <Box className="text-plugin">
      {isReadOnly ? (
        <ReadOnlyEditor content={content} />
      ) : (
        <Suspense fallback={<ReadOnlyEditor content={content} />}>
          <RichTextEditor id={id} content={content} />
        </Suspense>
      )}
    </Box>
  );
};
