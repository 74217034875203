/* eslint-disable jsx-a11y/alt-text */
import React, {
  HTMLProps,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import type { UploadsDto as MediaDto } from "../../dtos";
import styles from "./GalleryImage.module.scss";
import clsx from "clsx";
import { getImageProps, getSmallestFormat } from "../../utils/getImageProps";
import { useIsClient } from "../../hooks/useIsClient";
import useToggle from "../../hooks/useToggle";

interface LazyGalleryImageProps extends HTMLProps<HTMLImageElement> {
  image: MediaDto;
  root?: HTMLElement;
  threshold?: number;
  lazy?: boolean;
  shouldPreload?: boolean;
  onVisibilityChange?: (entry: IntersectionObserverEntry) => void;
  isMobile?: boolean;
  isVisible?: boolean;
}

const hasIOSupport = !!global.IntersectionObserver;

export function LazyGalleryImage({
  image,
  className,
  onVisibilityChange,
  root,
  threshold,
  lazy,
  shouldPreload,
  isMobile,
  isVisible = false,
  ...rest
}: LazyGalleryImageProps) {
  const isClient = useIsClient();
  const ref = useRef<HTMLImageElement>(null);
  const [hasError, setHasError] = useState(false);
  const [hasLoaded, toggleHasLoaded] = useToggle();

  const onError = useCallback(() => {
    setHasError(true);
  }, []);

  const imageProps = useMemo(() => {
    const fullImageProps = getImageProps(image);
    return fullImageProps;
  }, [isVisible, image, isMobile, lazy, isClient]);

  if (hasError) {
    return (
      <div className={styles.noImage}>
        <NoPhotographyIcon />
        <p>NO IMAGE FOUND</p>
      </div>
    );
  }

  const imgProps = hasLoaded || shouldPreload || isVisible ? imageProps : {};

  return (
    <img
      {...rest}
      {...imgProps}
      onLoad={toggleHasLoaded}
      className={clsx(styles.galleryImage, className)}
      onError={onError}
      ref={ref}
    />
  );
}
