/**
 * Recursively checks for deep equality between two values, with options to include or skip specific fields.
 * adapted from https://github.com/epoberezkin/fast-deep-equal/blob/master/src/index.jst
 * this allows to skip and include fields to check and is nearly twice as fast as the lodash.isEqual alternative
 * https://www.measurethat.net/Benchmarks/Show/11503/1/lodashisequal-vs-fast-deep-equal
 *
 * @param {*} a - The first value to compare.
 * @param {*} b - The second value to compare.
 * @param {string[]} [includeFields=[]] - An array of fields to include in the comparison. If empty, all fields are included unless specified in `skipFields`.
 * @param {string[]} [skipFields=[]] - An array of fields to skip in the comparison.
 * @returns {boolean} - Returns `true` if the values are deeply equal, `false` otherwise.
 *
 * @example
 * // Comparing two objects with specific fields to include
 * const obj1 = { name: 'Alice', age: 25, city: 'New York' };
 * const obj2 = { name: 'Alice', age: 25, city: 'Los Angeles' };
 * const result = equal(obj1, obj2, ['name', 'age']); // true
 *
 * @example
 * // Comparing two objects with specific fields to skip
 * const obj1 = { name: 'Alice', age: 25, city: 'New York' };
 * const obj2 = { name: 'Alice', age: 30, city: 'New York' };
 * const result = equal(obj1, obj2, [], ['age']); // true
 */
export const deepEquals = (
  a: any,
  b: any,
  includeFields: string[] = [],
  skipFields: string[] = []
): boolean => {
  if (a === b) {
    return true;
  }

  if (a && b && typeof a === "object" && typeof b === "object") {
    if (a.constructor !== b.constructor) {
      return false;
    }

    if (Array.isArray(a)) {
      if (a.length !== b.length) {
        return false;
      }
      return a.every((item, index) =>
        deepEquals(item, b[index], includeFields, skipFields)
      );
    }

    if (a instanceof Map && b instanceof Map) {
      if (a.size !== b.size) {
        return false;
      }
      for (const [key, value] of a.entries()) {
        if (
          !b.has(key) ||
          !deepEquals(value, b.get(key), includeFields, skipFields)
        ) {
          return false;
        }
      }
      return true;
    }

    if (a instanceof Set && b instanceof Set) {
      if (a.size !== b.size) {
        return false;
      }
      for (const value of a.values()) {
        if (!b.has(value)) {
          return false;
        }
      }
      return true;
    }

    if (a.constructor === RegExp) {
      return a.source === b.source && a.flags === b.flags;
    }
    if (a.valueOf !== Object.prototype.valueOf) {
      return a.valueOf() === b.valueOf();
    }
    if (a.toString !== Object.prototype.toString) {
      return a.toString() === b.toString();
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) {
      return false;
    }

    if (!keysA.every((key) => Object.prototype.hasOwnProperty.call(b, key))) {
      return false;
    }

    return keysA.every((key) => {
      if (skipFields.includes(key)) {
        return true;
      }
      if (includeFields.length > 0 && !includeFields.includes(key)) {
        return true;
      }
      if (key === "_owner" && (a as any).$$typeof) {
        // React-specific: avoid traversing React elements' _owner.
        // _owner contains circular references
        // and is not needed when comparing the actual elements (and not their owners)
        return true;
      }
      return deepEquals(a[key], b[key], includeFields, skipFields);
    });
  }

  // true if both NaN, false otherwise
  // eslint-disable-next-line no-self-compare
  return a !== a && b !== b;
};
