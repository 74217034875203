import React, { ReactNode, useMemo, useState } from "react";
import { Dialog } from "../../components/CustomDialog/CustomDialog";
import { DotyCandidatesDto } from "../../dtos";
import { Box, Button, CircularProgress, Paper } from "@mui/material";
import { API } from "../../client/API";
import { useAppData } from "../../providers/AppDataProvider";
import useCookie from "../../hooks/useCookie";
import { LazyGalleryImage } from "../../components/ScrollSnapGallery/LazyGalleryImage";
import { DotyPhotoButton } from "./components/DotyPhotoButton";
import { AdminOnly } from "../../components/AdminOnly/AdminOnly";
import DotyCardActions from "./DotyActions";
import { ProtectedComponent } from "../../components/ProtectedComponent/ProtectedComponent";
import { CommentsField } from "../../components/CommentsField/CommentsField";
import { CommentsList } from "../../components/CommentsList/CommentsList";

type Props = {
  doty: DotyCandidatesDto;
  isOpen: boolean;
  isMobile: boolean;
  onClose: () => void;
};

const useVoteButtonProps = (doty: DotyCandidatesDto) => {
  const { appData } = useAppData();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [voterId, setVoterId] = useCookie("voterId", appData.voterId, [
    doty.votes,
  ]);

  const handleVote = () => {
    setIsLoading(true);
    return API.post(`/dotycandidates/vote/${doty._id}`)
      .then((results) => {
        dispatchEvent(new CustomEvent("DOTY_SAVED", { detail: results }));
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const lastVote = useMemo(() => {
    return doty.votes.find(
      (vote: any) => typeof vote === "object" && vote.voterId === voterId
    );
  }, [doty.votes, voterId]);

  const isDisabled = useMemo<boolean>(() => {
    return !!lastVote;
  }, [lastVote]);

  const icon = useMemo<ReactNode>(() => {
    return isLoading ? (
      <CircularProgress color="inherit" size={16} />
    ) : undefined;
  }, [isLoading]);

  return {
    isDisabled,
    isLoading,
    icon,
    handleVote,
    lastVote,
  };
};

export const DotyDetailsDialog: React.FC<Props> = ({
  doty,
  isOpen,
  onClose,
  isMobile,
}) => {
  const { isDisabled, handleVote, isLoading, icon, lastVote } =
    useVoteButtonProps(doty);

  const [firstImage, ...allImages] = doty.images;

  const handleVoteClick = async () => {
    if (!isDisabled) {
      await handleVote();
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      PaperComponent={(props) => {
        return (
          <Paper
            {...props}
            onClick={(e) => e.stopPropagation()}
            elevation={0}
          />
        );
      }}
    >
      <Dialog.Header px={1} gap={1} isBackButton>
        <Box
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <span>{doty.fullName}</span>
          <Box display="flex" gap={1}>
            <DotyPhotoButton dotyId={doty._id} />
            <AdminOnly>
              <DotyCardActions doty={doty} />
            </AdminOnly>
          </Box>
        </Box>
      </Dialog.Header>

      <Dialog.Content p={0}>
        {firstImage && (
          <Box maxHeight="calc(100vh - 250px)" overflow="hidden">
            <LazyGalleryImage image={firstImage} />
          </Box>
        )}
        <Box padding={2}>{doty.description}</Box>
        {allImages.length > 0 && (
          <Box>
            {allImages.map((image) => {
              return (
                <Box key={image._id}>
                  <LazyGalleryImage image={image} />
                </Box>
              );
            })}
          </Box>
        )}
        <Box p={2}>
          <CommentsList
            referenceId={doty._id}
            numberOfComments={doty.commentsCount}
          />
        </Box>
      </Dialog.Content>
      <Dialog.Footer isCancelHidden>
        <Box flex={1} component="small" display="flex" flexDirection="column">
          <span>
            <b>{doty.votesCount} votes</b>
          </span>
          {lastVote && (
            <small>
              You voted for {doty.fullName} on{" "}
              {new Date((lastVote as any).createdAt).toLocaleString()}
            </small>
          )}
        </Box>
        <Box flex="0 0 auto">
          <Button
            fullWidth
            variant="contained"
            size="small"
            startIcon={icon}
            disabled={isDisabled || isLoading}
            onClick={handleVoteClick}
          >
            Vote for {doty.fullName}
          </Button>
        </Box>
      </Dialog.Footer>
    </Dialog>
  );
};
