import { useState, useCallback, useEffect } from "react";

interface CookieOptions {
  days?: number;
  path?: string;
}

type SetCookieFunction = (value: string, options?: CookieOptions) => void;
type RemoveCookieFunction = () => void;

export const getCookieValue = (key: string, initialValue: string) => {
  if (typeof window === "undefined") {
    return initialValue;
  }

  const storedValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(key + "="));

  if (storedValue) {
    return storedValue.split("=")[1];
  }

  return initialValue;
};

const useCookie = (
  key: string,
  initialValue: string,
  deps: any[] = []
): [string | null, SetCookieFunction, RemoveCookieFunction] => {
  const [item, setItem] = useState<string | null>(() => {
    return getCookieValue(key, initialValue);
  });

  useEffect(() => {
    setItem(getCookieValue(key, initialValue));
  }, deps);

  const setCookie: SetCookieFunction = useCallback(
    (value, options = {}) => {
      if (typeof window !== "undefined") {
        const { days = 7, path = "/" } = options;
        const expires = new Date(Date.now() + days * 864e5).toUTCString();
        document.cookie = `${key}=${value}; expires=${expires}; path=${path}`;
        setItem(value);
      }
    },
    [key]
  );

  const removeCookie: RemoveCookieFunction = useCallback(() => {
    if (typeof window !== "undefined") {
      document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      setItem(null);
    }
  }, [key]);

  return [item, setCookie, removeCookie];
};

export default useCookie;
