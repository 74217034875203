import clsx from "clsx";
import NavigatePreviousIcon from "@mui/icons-material/ArrowBackIosNew";
import NavigateNextIcon from "@mui/icons-material/ArrowForwardIos";
import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  ReactNode,
} from "react";
import styles from "./ScrollSnapGallery.module.scss";
import IconButton from "@mui/material/IconButton";
import type { UploadsDto as MediaDto } from "../../dtos";
import { Box, debounce, SxProps } from "@mui/material";
import { LazyGalleryImage } from "./LazyGalleryImage";
import { LazyLightboxGallery } from "./LazyLightboxGallery";
import { useIsClient } from "../../hooks/useIsClient";
import { deepEquals } from "../../utils/deepEquals";
import { useIsMobile } from "../../hooks/useIsMobile";
import {
  VisiblePercentageConfig,
  getVisiblePercentages,
} from "./scrollHelpers";

const calculateWidth = (
  image: MediaDto,
  containerHeight = 400,
  isMobile = true,
  isClient = false
) => {
  const { height, width } = image;
  const calculatedWidth = (containerHeight * width) / height;
  if (isClient && isMobile) {
    if (calculatedWidth > window.innerWidth) {
      return window.innerWidth;
    }
  }
  return calculatedWidth;
};

const handleScrollTo = (
  scroller: HTMLDivElement,
  imageEls: Record<string, HTMLElement>,
  nextIdx: number
) => {
  const element = Object.values(imageEls)[nextIdx];
  if (element) {
    const { scrollLeft } = scroller;
    const { left } = element.getBoundingClientRect();
    scroller.scrollTo({ left: scrollLeft + left, top: 0, behavior: "smooth" });
  }
};

const handleScrollToMobile = (scroller: HTMLDivElement, multiplier: 1 | -1) => {
  const { scrollLeft, offsetWidth } = scroller;
  const newLeftPos = offsetWidth * multiplier + scrollLeft;

  scroller.scrollTo({ left: newLeftPos, top: 0, behavior: "smooth" });
};

interface BasicScrollSnapGalleryProps {
  images: MediaDto[];
  currentIndex?: number;
  editToggle?: ReactNode;
  isMobile?: boolean;
  disableKeyboardEvents?: boolean;
  onIndexChange?: (index: number) => void;
  disableLightboxGallery?: boolean;
  pagingType?: "bullet" | "number";
  maxHeightMd?: number | string;
  maxHeightSm?: number | string;
  maxHeightXs?: number | string;
  className?: string;
  sx?: SxProps;
  isPagingHidden?: boolean;
  alwaysShowButtons?: boolean;
  isWindowKeyboardEvent?: boolean;
  isContained?: boolean;
}

export function ScrollSnapGallery({
  disableKeyboardEvents,
  images,
  editToggle,
  isMobile,
  maxHeightXs = 250,
  maxHeightSm = 350,
  maxHeightMd = 450,
  disableLightboxGallery,
  className,
  isPagingHidden = false,
  sx,
  onIndexChange,
  alwaysShowButtons = false,
  isWindowKeyboardEvent = false,
  isContained = false,
}: BasicScrollSnapGalleryProps) {
  const isMobileDevice = useIsMobile();
  const isClient = useIsClient();
  const [index, setIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hideNext, setHideNext] = useState(false);
  const imageRefs = useRef<Record<string, HTMLElement>>({});
  const [visiblePercentages, setVisiblePercentages] = useState<
    VisiblePercentageConfig[]
  >([]);
  const scrollRef = useRef(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const preventScroll = useRef(false);
  const [isScrollPrevented, setIsScrollPrevented] = useState(false);

  useEffect(() => {
    onIndexChange?.(index);
  }, [index]);

  const showPaging = images.length > 1 && !isPagingHidden;

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const setImageRef = useCallback((id: string) => {
    return (imageCard: HTMLDivElement) => {
      if (imageCard) {
        imageRefs.current[id] = imageCard;
      }
    };
  }, []);

  const onVisibilityChange = useCallback((entry: any) => {
    if (entry.isIntersecting) {
      const { target } = entry;
      const imgIndex = parseInt(target.getAttribute("data-image-index"));
      if (!isNaN(imgIndex)) {
        setIndex(imgIndex);
        preventScroll.current = true;
      }
    }
  }, []);

  const nextIndex = index < images.length - 1 ? index + 1 : undefined;

  const prevIndex = index > 0 ? index - 1 : undefined;

  const onNextClick = useCallback(
    (e?: any) => {
      e?.stopPropagation?.();
      e?.preventDefault?.();

      if (!nextIndex || hideNext) {
        return;
      }

      if (isMobile) {
        handleScrollToMobile(scrollRef.current, 1);
      } else {
        handleScrollTo(scrollRef.current, imageRefs.current, nextIndex);
      }
    },
    [nextIndex, isMobile, hideNext]
  );

  const onPrevClick = useCallback(
    (e?: any) => {
      e?.stopPropagation?.();
      e?.preventDefault?.();
      if (prevIndex === undefined) {
        return;
      }
      if (isMobile) {
        handleScrollToMobile(scrollRef.current, -1);
      } else {
        handleScrollTo(scrollRef.current, imageRefs.current, prevIndex);
      }
    },
    [prevIndex, isMobile]
  );

  useEffect(() => {
    if (isScrollPrevented) {
      setTimeout(() => {
        setIsScrollPrevented(false);
      }, 250);
    }
  }, [isScrollPrevented]);

  useEffect(() => {
    const scrollEl = scrollRef.current;

    const handleScroll = () => {
      const nextVisiblePercentages = getVisiblePercentages(
        imageRefs.current,
        wrapperRef.current
      );
      setVisiblePercentages(nextVisiblePercentages);
      const [visibleImage] = nextVisiblePercentages;
      setIndex((prev) => {
        if (visibleImage && visibleImage.idx !== prev) {
          return visibleImage.idx;
        }
        return prev;
      });
    };

    handleScroll();

    scrollEl.addEventListener("scroll", handleScroll, false);

    return () => {
      scrollEl.removeEventListener("scroll", handleScroll, false);
    };
  }, []);

  useEffect(() => {
    if (!scrollRef.current) return;
    let target: Window | HTMLElement = document.body;

    if (disableKeyboardEvents && !isWindowKeyboardEvent) {
      target = wrapperRef.current;
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        onPrevClick();
      } else if (e.key === "ArrowRight") {
        onNextClick();
      }
    };

    target.addEventListener("keyup", handleKeyDown, false);

    return () => {
      target.removeEventListener("keyup", handleKeyDown, false);
    };
  }, [onNextClick, onPrevClick, disableKeyboardEvents, isWindowKeyboardEvent]);

  const shouldRenderImages = images.filter(Boolean).length > 0;

  const showPrevious =
    prevIndex !== undefined && (!isMobile || disableKeyboardEvents);

  const showNext =
    nextIndex !== undefined &&
    !hideNext &&
    (!isMobile || disableKeyboardEvents);

  return (
    <Box
      ref={wrapperRef}
      tabIndex={0}
      id="paginated_gallery"
      className={clsx(styles.gallery2, className)}
      sx={sx}
    >
      <Box
        height={{ xs: maxHeightXs, sm: maxHeightSm, md: maxHeightMd }}
        display="flex"
        ref={scrollRef}
        className={clsx(styles.scroller2, {
          [styles.isContained]: isContained,
        })}
      >
        {shouldRenderImages &&
          images.map((image, i) => {
            const isCurrent = i === index;
            const isVisible = visiblePercentages.some(
              (visible) => visible.id === image._id && visible.isVisible
            );
            const shouldPreload = i === index - 1 || i === index + 1;
            return (
              <Box
                key={image._id}
                height="100%"
                onClick={isMobile && toggleDialog}
                width={
                  isMobile
                    ? "100%"
                    : calculateWidth(image, 400, isMobile, isClient)
                }
                data-image-idx={i}
                ref={setImageRef(image._id)}
                className={clsx(styles.cardBasic, {
                  [styles.cardBasicMobile]: isMobile,
                })}
              >
                <LazyGalleryImage
                  image={image}
                  alt={image.name}
                  root={scrollRef.current}
                  shouldPreload={
                    isClient ? shouldPreload || isCurrent : isCurrent
                  }
                  data-image-index={i}
                  isVisible={isVisible}
                />
              </Box>
            );
          })}
      </Box>
      <IconButton
        onClick={onPrevClick}
        disabled={!showPrevious}
        className={clsx(styles.btn, styles.prev, {
          [styles.smallIcon]: true,
          [styles.btnHidden]: !showPrevious && !alwaysShowButtons,
        })}
      >
        <NavigatePreviousIcon />
      </IconButton>

      <IconButton
        onClick={onNextClick}
        disabled={!showNext}
        className={clsx(styles.btn, styles.next, {
          [styles.smallIcon]: true,
          [styles.btnHidden]: !showNext && !alwaysShowButtons,
        })}
      >
        <NavigateNextIcon />
      </IconButton>
      {editToggle}
      {isMobile && showPaging && (
        <Box className={styles.paging}>
          {index + 1} / {images.length}
        </Box>
      )}
      {isMobile && !disableLightboxGallery && (
        <Box className={styles.expandToggle}>
          <LazyLightboxGallery
            isMobile={isMobile}
            images={images}
            currentIndex={dialogOpen ? 0 : undefined}
            onClose={toggleDialog}
          />
        </Box>
      )}
    </Box>
  );
}
