import { RowPlugin } from "./RowPlugin";
import { RowEditor } from "./RowEditor";
import { PluginConfig } from "../types";

const RowPluginConfig: PluginConfig = {
  name: "Row",
  Component: RowPlugin,
  Editor: RowEditor,
  allowsChildren: true,
};

export default RowPluginConfig;
