import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogProps,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { FormEvent, useCallback, useState } from "react";
import { useFormSchema } from "../../widgets/hooks/useFormSchema";
import { FormSchemaForm } from "../FormSchemaForm";
import styles from "./FormDialog.module.scss";
import { Close } from "@mui/icons-material";
import { API } from "../../client/API";
import { useIsMobile } from "../../hooks/useIsMobile";
import clsx from "clsx";
import GoogleAuth from "../GoogleAuth/GoogleAuth";

type Props<T = any> = {
  formSlug: string;
  isOpen: boolean;
  onClose: () => void;
  initialValues?: any;
  onSubmitSuccess?: (values: T) => void;
  onSubmitError?: (error: Error) => void;
  isMobile?: boolean;
};

function FormDialogPaperComponent<T = any>({
  formSlug,
  onClose,
  initialValues,
  onSubmitSuccess,
  onSubmitError,
  isMobile,
}: Omit<Props<T>, "isOpen">) {
  const [values, setValues] = useState(initialValues || {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formSchema, isLoading } = useFormSchema(formSlug);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { submitUrl, submitMethod, eventName } = formSchema;

    if (isSubmitting) return;

    setIsSubmitting(true);

    if (submitUrl && submitMethod) {
      await API[submitMethod.toLowerCase()](submitUrl, values)
        .then((results) => {
          window.dispatchEvent(new CustomEvent(eventName, { detail: results }));
          onSubmitSuccess?.(results);
          onClose();
          setIsSubmitting(false);
        })
        .catch((error) => {
          setIsSubmitting(false);
          onSubmitError?.(error);
        });
    }
  };

  if (isLoading) {
    return (
      <Paper
        elevation={6}
        sx={{
          height: isMobile ? "100%" : undefined,
          minHeight: 300,
          width: "100%",
          padding: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={60} />
      </Paper>
    );
  }

  return (
    <Box
      onSubmit={handleSubmit}
      component="form"
      className={clsx(styles.formWrapper, {
        [styles.formWrapperMobile]: isMobile,
      })}
      bgcolor="#222"
      borderRadius={1}
      boxShadow={6}
      sx={{
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))",
      }}
    >
      <Box className={styles.formHeader}>
        <Box className={styles.formTitle}>
          <b>{formSchema.formName}</b>
        </Box>
        <IconButton
          size="small"
          onClick={onClose}
          className={styles.closeButton}
        >
          <Close />
        </IconButton>
      </Box>
      <Box className={styles.formBody}>
        <FormSchemaForm
          form={formSchema}
          initialValues={initialValues}
          onChange={setValues}
          isFieldsOnly
        />
      </Box>
      <Box className={styles.formFooter}>
        {formSchema.formSlug === "login-form" && <GoogleAuth />}
        <Button size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="small"
          startIcon={
            isSubmitting ? (
              <CircularProgress size={16} color="inherit" />
            ) : undefined
          }
          // disabled={isSubmitting}
          type="submit"
          variant="contained"
          color="primary"
        >
          {formSchema.submitButtonText || "Submit"}
        </Button>
      </Box>
    </Box>
  );
}

export function FormDialog<T = any>({
  formSlug,
  isOpen,
  onClose,
  initialValues,
  maxWidth = "sm",
  onSubmitSuccess,
  onSubmitError,
}: Props<T> & Omit<DialogProps, "open">) {
  const isMobile = useIsMobile();
  const renderForm = useCallback(() => {
    return (
      <Container
        sx={{
          maxHeight: isMobile ? "100%" : "calc(100vh - 80px)",
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
          flexDirection: "column",
          height: isMobile ? "100%" : undefined,
          px: isMobile ? 0 : undefined,
        }}
        maxWidth={maxWidth}
      >
        <FormDialogPaperComponent
          isMobile={isMobile}
          formSlug={formSlug}
          onClose={onClose}
          initialValues={initialValues}
          onSubmitError={onSubmitError}
          onSubmitSuccess={onSubmitSuccess}
        />
      </Container>
    );
  }, [
    formSlug,
    isMobile,
    maxWidth,
    onSubmitSuccess,
    onSubmitError,
    initialValues,
  ]);

  return (
    <Dialog
      maxWidth={maxWidth}
      open={isOpen}
      onClose={onClose}
      PaperComponent={renderForm}
    />
  );
}
