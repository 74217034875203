import { PageElementDto } from "../../../dtos";
import { generateId } from "../../../utils/generateId";

const generateNewId = (value: string = "") => {
  const [type] = value.split("-");
  return generateId(type?.toLocaleLowerCase() || "id");
};

export const getElementType = (
  element: PageElementDto,
  withElementType?: boolean
) => {
  if (element.isSection) {
    return "SECTION";
  }

  if (element.elementType === "ROW" || element.type === "ROW") {
    return "ROW";
  }

  if (element.type && withElementType) {
    return element.type.toUpperCase();
  }

  return "WIDGET";
};

export const removeIds = (data: any) => {
  if (!data) {
    return null;
  }

  if (Array.isArray(data)) {
    return data.reduce((mappedValues: any[], item: any) => {
      if (typeof item === "object" && Object.keys(item).length > 0) {
        const value = removeIds(item);
        mappedValues.push(value);
        return mappedValues;
      } else {
        mappedValues.push(item);
        return mappedValues;
      }
    }, []);
  }

  return Object.entries(data).reduce((mappedValues: any, [key, value], idx) => {
    if (Array.isArray(value)) {
      return {
        ...mappedValues,
        [key]: value.length > 0 ? removeIds(value) : [],
      };
    }
    // @ts-ignore
    if (key === "id") {
      const newValue = generateNewId(getElementType(data, true));
      return { ...mappedValues, [key]: newValue };
    }

    return { ...mappedValues, [key]: value };
  }, {});
};

export const setElementData = (
  id: string,
  data: object,
  elements: PageElementDto[]
): PageElementDto[] => {
  return elements.map((element) => {
    if (element.id === id) {
      return { ...element, data: { ...element.data, ...data } };
    }
    const { elements: childElements = [] } = element;
    if (childElements.length) {
      return { ...element, elements: setElementData(id, data, childElements) };
    }
    return element;
  });
};

export const updatePageElement = (
  id: string,
  updatedElement: PageElementDto,
  elements: PageElementDto[]
): PageElementDto[] => {
  return elements.map((element) => {
    if (element.id === id) {
      return { ...element, ...updatedElement };
    }
    const { elements: childElements = [] } = element;
    if (childElements.length) {
      return {
        ...element,
        elements: updatePageElement(id, updatedElement, childElements),
      };
    }
    return element;
  });
};

export const removeElementFromPage = (
  id: string,
  elements: PageElementDto[]
): PageElementDto[] => {
  return elements
    .filter((element) => element.id !== id)
    .map((element) => {
      return {
        ...element,
        elements: element.elements?.length
          ? removeElementFromPage(id, element.elements)
          : [],
      };
    });
};

export const duplicateElementOnPage = (
  id: string,
  elements: PageElementDto[]
) => {
  return elements.reduce((mappedElements: PageElementDto[], element) => {
    const { elements: childElements = [] } = element;
    if (element.id === id) {
      const clonedElement = removeIds(element);
      mappedElements.push(element, clonedElement);
      return mappedElements;
    }
    if (childElements.length) {
      mappedElements.push({
        ...element,
        elements: duplicateElementOnPage(id, childElements),
      });
    } else {
      mappedElements.push(element);
    }
    return mappedElements;
  }, []);
};

export const getElementData = (
  id: string,
  elements: PageElementDto[],
  ancestorIds: string[] = []
): PageElementDto | undefined => {
  return elements.reduce(
    (foundElement: PageElementDto | undefined, element) => {
      if (element.id === id) {
        return { ...element, ancestorIds };
      }

      const { elements: childElements = [] } = element;

      if (childElements.length) {
        return getElementData(id, childElements, [...ancestorIds, element.id]);
      }

      return foundElement;
    },
    undefined
  );
};

export const findElementDeep = (
  elementId: string,
  pageElements: PageElementDto[],
  ancestorIds: string[] = []
): PageElementDto => {
  for (const element of pageElements) {
    const { id, elements } = element;

    if (id === elementId) {
      return { ...element, ancestorIds } as PageElementDto;
    }

    if (elements.length) {
      const child = findElementDeep(elementId, elements, [...ancestorIds, id]);

      if (child) {
        return child;
      }
    }
  }

  return undefined;
};

export const addWidgetToElement = (
  elementId: string,
  newElement: PageElementDto,
  elements: PageElementDto[]
) => {
  return elements.map((element) => {
    const { id, elements: childElements = [] } = element;

    if (id === elementId) {
      return {
        ...element,
        elements: [...childElements, newElement],
      };
    }

    if (!childElements.length) {
      return element;
    }

    return {
      ...element,
      elements: addWidgetToElement(elementId, newElement, childElements),
    };
  });
};
