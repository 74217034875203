export type SortOption = {
  label: string;
  key: string;
  value: number | string;
  isDefault?: boolean;
};

export const SORT_OPTIONS: SortOption[] = [
  { label: "Newest first", key: "createdAt", value: "desc", isDefault: true },
  { label: "Last updated", key: "updatedAt", value: "desc" },
  { label: "Most votes", key: "votesCount", value: "desc" },
  { label: "Least votes", key: "votesCount", value: "asc" },
];

export const findSelectedOption = (
  sort: Record<string, any> = {}
): SortOption => {
  const foundOption = SORT_OPTIONS.find(
    (option) => sort[option.key] === option.value
  );
  const selectedOption =
    foundOption || SORT_OPTIONS.find((option) => option.isDefault);
  return selectedOption;
};
