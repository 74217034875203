import { useEffect, useState } from "react";
import { useUser } from "../providers/UserProvider";

export function useIsAdmin(): boolean {
  const { user } = useUser();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    setIsAdmin(user?.role?.type === "ADMIN");
  }, [user]);

  return isAdmin;
}
