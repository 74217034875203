import { JoshPlugin } from './JoshPlugin';
import { JoshEditor } from './JoshEditor';
import { PluginConfig } from '../types';

const JoshPluginConfig: PluginConfig = {
	name: "Josh",
	Component: JoshPlugin,
	Editor: JoshEditor,
}

export default JoshPluginConfig;