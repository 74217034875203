import { CellPlugin } from "./CellPlugin";
import { CellEditor } from "./CellEditor";
import { PluginConfig } from "../types";

const CellPluginConfig: PluginConfig = {
  name: "Cell",
  Component: CellPlugin,
  Editor: CellEditor,
  allowsChildren: true,
  preventWrapper: true,
};

export default CellPluginConfig;
