import React from "react";
import { WidgetDto } from "../types";

export const FormEditor: React.FC<WidgetDto> = ({ data }) => {
  return (
    <div className={data.className} style={data.sx}>
      Editor template
    </div>
  );
};
