import React, { ChangeEvent, useState, FC } from "react";
import { Box, CircularProgress, IconButton, MenuItem } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { API } from "../../../client/API";
import { ErrorOutline } from "@mui/icons-material";
import Check from "@mui/icons-material/Check";
import { DotyCandidatesDto } from "../../../dtos";

const getFormData = (files: FileList): FormData => {
  const formData = new FormData();

  Array.from(files).forEach((file, idx) => {
    formData.append("images", file as any, file.name);
  });

  return formData;
};

type Props = {
  dotyId: string;
  isMenuItem?: boolean;
  onSuccess?: (doty: DotyCandidatesDto) => void;
};

export const DotyPhotoButton: FC<Props> = ({
  dotyId,
  isMenuItem,
  onSuccess,
}) => {
  const [status, setStatus] = useState<"loading" | "complete" | "error" | null>(
    null
  );

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    const formData = getFormData(files);

    if (status !== null) return;
    setStatus("loading");

    API.uploadImages(`/dotycandidates/upload/${dotyId}`, formData)
      .then((data) => {
        window.dispatchEvent(new CustomEvent("DOTY_SAVED", { detail: data }));
        setStatus("complete");
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        console.error(err);
        setStatus("error");
      });

    setTimeout(() => {
      setStatus(null);
    }, 1500);
  };

  const renderIcon = () => {
    switch (status) {
      case "loading":
        return <CircularProgress />;
      case "complete":
        return <Check />;
      case "error":
        return <ErrorOutline />;
      default:
        return <AddAPhotoIcon />;
    }
  };

  if (isMenuItem) {
    return (
      <MenuItem
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={(e) => e.stopPropagation()}
        component="label"
      >
        {renderIcon()} Photos
        <Box
          component="input"
          sx={{ opacity: 0, left: 0, width: "1px", position: "absolute" }}
          multiple={true}
          name="images"
          onChange={onChange}
          type="file"
        />
      </MenuItem>
    );
  }

  return (
    <Box position="relative">
      <IconButton>
        <Box
          component="input"
          sx={{ opacity: 0, left: 0, right: 0, position: "absolute" }}
          multiple={true}
          name="images"
          onChange={onChange}
          type="file"
        />
        {renderIcon()}
      </IconButton>
    </Box>
  );
};
