import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { WidgetDto } from "../types";
import useResizeObserver, {
  DOMRectProperties,
} from "../../hooks/useResizeObserver";
import "./YoutubePlugin.scss";
import useToggle from "../../hooks/useToggle";

const BASE_WIDTH = 1440;

const getProportionateHeight = (maxHeight: number) => {
  const innerWidth = window.innerWidth;

  if (innerWidth > BASE_WIDTH) {
    return maxHeight;
  }

  return (innerWidth / BASE_WIDTH) * maxHeight;
};

export const YoutubePlugin: React.FC<WidgetDto> = ({ data }) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const [canShowVideo, setCanShowVideo] = useToggle();
  const {
    videoUrl,
    youtubeUrl,
    maxHeight,
    autoplay,
    controls,
    muted,
    baseHeight = 315,
    baseWidth = 560,
  } = data;

  const rect = useResizeObserver(wrapperRef, {
    useBoundingClientRect: true,
    contentRectProperties: [
      DOMRectProperties.width,
      DOMRectProperties.height,
      DOMRectProperties.top,
    ],
  });

  useLayoutEffect(() => {
    const handleSizing = () => {
      const widthProportionate = globalThis.innerWidth / baseWidth;
      const dynamicHeight = baseHeight * widthProportionate;
      const wrapperHeight =
        dynamicHeight < maxHeight ? maxHeight : dynamicHeight;
      wrapperRef.current.style.setProperty(
        "width",
        `${globalThis.innerWidth}px`
      );
      wrapperRef.current.style.setProperty("height", `${dynamicHeight}px`);
    };
    handleSizing();

    window.addEventListener("resize", handleSizing, false);

    return () => {
      window.removeEventListener("resize", handleSizing, false);
    };
  }, [maxHeight, baseHeight, baseWidth]);

  const renderVideo = useCallback(() => {
    if (!youtubeUrl && !videoUrl) {
      return null;
    }

    if (videoUrl) {
      return (
        <video className="video-iframe" controls width="100%">
          <source src={videoUrl} />
        </video>
      );
    }

    if (youtubeUrl) {
      const searchParams = new URLSearchParams(youtubeUrl.split("?")[1]);
      const autoplayValue = !!autoplay ? 1 : 0;
      const controlsValue = !!controls ? 1 : 0;
      const mutedValue = !!muted ? 1 : 0;
      const videoId = searchParams.get("v");
      const videoSrc = `https://www.youtube.com/embed/${videoId}?rel=0&controls=${controlsValue}&autoplay=${autoplayValue}&mute=${mutedValue}`;

      return (
        <div className="youtube-wrapper">
          <iframe
            width="100%"
            height="100%"
            src={videoSrc}
            frameBorder={0}
            className="video-iframe"
            title="YouTube video player"
            allow="accelerometer; autoplay; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      );
    }
  }, [videoUrl, youtubeUrl, autoplay, controls]);

  return (
    <div style={{ maxHeight }} className="video-wrapper" ref={wrapperRef}>
      {renderVideo()}
    </div>
  );
};
