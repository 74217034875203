import { FormPlugin } from "./FormPlugin";
import { FormEditor } from "./FormEditor";
import { PluginConfig } from "../types";

const FormPluginConfig: PluginConfig = {
  name: "form-plugin",
  Component: FormPlugin,
  Editor: FormEditor,
  allowsChildren: true,
  preventWrapper: true,
};

export default FormPluginConfig;
