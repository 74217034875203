import React from "react";
import { NavigationItemDto } from "../../dtos";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";

type HeaderMenuItemProps = {
  item: NavigationItemDto;
};

export const HeaderMenuItem: React.FC<HeaderMenuItemProps> = ({ item }) => {
  const { href, label, variant, color, links, fullWidth, id, target } = item;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (links?.length) {
    return (
      <>
        <Button
          id={id}
          color={color}
          variant={variant}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {label}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": id,
          }}
        >
          {links.map((link) => {
            return <HeaderMenuItem item={link} key={link.id} />;
          })}
        </Menu>
      </>
    );
  }
  return (
    <Button
      component={Link as any}
      to={href}
      color={color as any}
      variant={variant as any}
      sx={{ display: "block" }}
      target={target}
      fullWidth={fullWidth}
    >
      {label}
    </Button>
  );
};
