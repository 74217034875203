import { Theme, useMediaQuery } from "@mui/material";

export const useBreakPoint = () => {
  const isXL = useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"));
  const isLG = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const isMD = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const isSM = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  if (isLG) {
    return "lg";
  }

  if (isMD) {
    return "md";
  }

  if (isSM) {
    return "sm";
  }

  return "xs";
};
