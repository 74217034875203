import React, { MouseEvent, useEffect, useState } from "react";
import type { DotyCandidatesDto } from "../../dtos";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import { ScrollSnapGallery } from "../../components/ScrollSnapGallery/ScrollSnapGallery";
import { useIsMobile } from "../../hooks/useIsMobile";
import "./DotyCard.scss";
import { useDotyToggle } from "./useDotyToggle";
import { DotyDetailsDialog } from "./DotyDetailsModal";
import { DotyCardMobile } from "./components/DotyCardMobile/DotyCardMobile";
import { useHistory } from "react-router-dom";
import { useAppData } from "../../providers/AppDataProvider";

type Props = {
  doty: DotyCandidatesDto;
};

export const DotyCard: React.FC<Props> = ({ doty: initialDoty }) => {
  const [doty, setDoty] = useState(initialDoty);
  const { setAppData } = useAppData();
  const { isOpen, toggleIsOpen } = useDotyToggle(doty._id);
  const history = useHistory();
  const isMobile = useIsMobile();

  const handleLinkClick = (
    e: MouseEvent<HTMLAnchorElement | HTMLDivElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setAppData((prev) => ({
      ...prev,
      doty,
    }));
    history.push(`/doty/${doty._id}`);
  };

  useEffect(() => {
    setDoty(initialDoty);
  }, [initialDoty]);

  if (isMobile) {
    return (
      <DotyCardMobile
        handleItemClick={handleLinkClick}
        doty={doty}
        setDoty={setDoty}
      />
    );
  }

  return (
    <Card
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={handleLinkClick}
    >
      {!isMobile && (
        <CardHeader
          titleTypographyProps={{
            sx: {
              fontSize: "1rem",
              fontWeight: 600,
            },
          }}
          title={doty.fullName}
          subheader={`${doty.votesCount} votes`}
        />
      )}

      <ScrollSnapGallery
        maxHeightXs={275}
        maxHeightSm={350}
        isMobile
        disableKeyboardEvents
        disableLightboxGallery
        images={doty.images}
      />
      {!isMobile && (
        <>
          <CardContent sx={{ fontSize: "0.85rem", flex: 1, pb: 1 }}>
            {doty.description}
          </CardContent>
          <CardActions>
            <Button
              component="a"
              href={`/doty/${doty._id}`}
              onClick={handleLinkClick}
              fullWidth
              variant="outlined"
            >
              View {doty.fullName}
            </Button>
          </CardActions>
        </>
      )}
      {isMobile && (
        <CardHeader
          sx={{
            position: "absolute",
            width: "100%",
            bottom: "0",
            px: 1,
            py: 2,
            gap: 1,
            background:
              "linear-gradient(0deg, rgba(0,0,0,1) 27%, rgba(255,255,255,0) 100%);",
          }}
          titleTypographyProps={{
            sx: {
              fontSize: "1rem",
              fontWeight: 600,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            },
          }}
          subheaderTypographyProps={{
            sx: {
              fontSize: "0.85rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            },
          }}
          classes={{
            action: "action-mobile",
            content: "content-mobile",
          }}
          action={
            <Box>
              <Button
                sx={{ fontSize: 12, padding: 0.5, minWidth: 0 }}
                size="small"
                variant="outlined"
              >
                View
              </Button>
            </Box>
          }
          title={doty.fullName}
          subheader={`${doty.votesCount} total votes`}
        />
      )}
      <DotyDetailsDialog
        isMobile={isMobile}
        isOpen={isOpen}
        onClose={toggleIsOpen}
        doty={doty}
      />
    </Card>
  );
};
