export const importEditorIcons = () => {
  const hasIcons = document.getElementById("MATERIAL_ICONS");

  if (!hasIcons) {
    const link = document.createElement("link");
    link.id = "MATERIAL_ICONS";
    link.setAttribute("rel", "stylesheet");
    link.setAttribute(
      "href",
      "https://fonts.googleapis.com/icon?family=Material+Icons"
    );
    document.head.appendChild(link);
  }
};
