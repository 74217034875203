import { TextPlugin } from "./TextPlugin";
// import { TextEditor } from './TextEditor';
import { PluginConfig } from "../types";

const TextPluginConfig: PluginConfig = {
  name: "Text",
  Component: TextPlugin,
  // Editor: TextEditor,
};

export default TextPluginConfig;
