import React, { useEffect } from "react";
import { WidgetDto } from "../types";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import useToggle from "../../hooks/useToggle";
import { ProtectedFormDialog } from "./FormDialog";

export const FormDialogPlugin: React.FC<WidgetDto> = ({ data }) => {
  const [isOpen, toggleDialog, open, close] = useToggle();
  const { formSlug, dialogTitle, isProtected } = data;

  return (
    <>
      <Button variant="outlined" onClick={toggleDialog}>
        Add candidate
      </Button>
      <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={toggleDialog}>
        <DialogTitle sx={{ px: 2, py: 1 }}>{dialogTitle}</DialogTitle>
        <DialogContent sx={{ padding: 1 }}>
          <ProtectedFormDialog
            isProtected={isProtected}
            formSlug={formSlug}
            handleSuccess={close}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
