import React from "react";
import { WidgetDto } from "../types";
import { Box } from "@mui/material";
import { getImageProps } from "../../utils/getImageProps";
import "./ImagePlugin.scss";
import clsx from "clsx";

export const ImagePlugin: React.FC<WidgetDto> = ({ data = {} }) => {
  const {
    image,
    sx = {},
    positionX = "center",
    positionY = "center",
    caption,
    className,
    maxHeight,
    isElevated,
    isRounded,
  } = data;
  const imageProps = getImageProps(image);

  return (
    <Box
      style={{ "--position-x": positionX, "--position-y": positionY } as any}
      className={clsx("wrapper", className)}
      minHeight={100}
      sx={sx}
    >
      {imageProps && (
        <img
          style={{ maxHeight }}
          className={clsx("image", {
            "is-elevated": isElevated,
            "is-rounded": isRounded,
          })}
          {...imageProps}
          srcSet={undefined}
        />
      )}
      <h1>{caption}</h1>
    </Box>
  );
};
