import React, { FC, useCallback } from "react";
import { usePageEditor } from "../../PageStore/PageEditorProvider";
import { useElement } from "../PageElementProvider";
import { createPortal } from "react-dom";
import "./ElementOverlay.scss";
import { getElementType } from "../../PageStore/pageUtils";

export const ElementOverlay: FC = () => {
  const { hoveredId } = usePageEditor();
  const { element, elementRef } = useElement();

  const setOverlayRef = useCallback((el: HTMLDivElement) => {
    if (el) {
      //  el.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });
    }
  }, []);

  if (!element || !elementRef || element.id !== hoveredId) {
    return null;
  }

  const getOverlayStyles = () => {
    const { width, height, top, left } = elementRef.getBoundingClientRect();

    return {
      width,
      height,
      top: top + window.scrollY,
      left: left + window.scrollX,
    };
  };

  return createPortal(
    <div
      style={getOverlayStyles()}
      ref={setOverlayRef}
      className="element-overlay"
    >
      <span className="plugin-title">{getElementType(element, true)}</span>
    </div>,
    document.body
  );
};
