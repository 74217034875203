import React from "react";
import { WidgetDto } from "../types";

export const JoshPlugin: React.FC<WidgetDto> = ({ data }) => {
  return (
    <div>
      {/* Component logic goes here */}
      {JSON.stringify(data, null, 2)}
      This is josh shit
    </div>
  );
};
