import { useMemo } from "react";
import { useAppData } from "../../providers/AppDataProvider";
import { NavigationsDto } from "../../dtos";

const selectPrimaryNavItems = (appData: any, slug: string = "app-header") => {
  return appData?.navigations?.find((nav) => nav.slug === slug)?.links || [];
};

export function useNavItems(slug = "app-header"): NavigationsDto["links"] {
  const { appData } = useAppData();

  return useMemo(() => {
    return selectPrimaryNavItems(appData, slug);
  }, [appData?.navigations, slug]);
}
