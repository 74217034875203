import React from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { UploadsDto } from "../dtos";
import { useSiteSettings } from "../hooks/useSiteSettings";

type SiteMetaProps = {
  image?: UploadsDto;
  title?: string;
  description?: string;
  keywords?: string;
  favicon?: UploadsDto | string;
};

const getBaseUrl = (url: string, baseURL = "http://henrylatour.com") => {
  return [baseURL, url].join("");
};

const getImageURL = (url: string, baseURL = "http://henrylatour.com") => {
  const imgUrl = new URL(baseURL);
  imgUrl.pathname = url;
  return imgUrl.toString();
};

const getTitleProp = (title: string, siteTitle: string) => {
  return [siteTitle, title].filter(Boolean).join(" - ");
};

const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
const stripHtml = (str: string = "") => {
  return str.replace(htmlRegexG, "");
};

function renderMetaImage(image: UploadsDto, baseUrl: string) {
  if (image) {
    return [
      <meta
        key={1}
        property="og:image"
        content={getImageURL(image.url, baseUrl)}
      />,
      <meta key={2} property="og:image:type" content={image.mime} />,
      <meta key={3} property="og:image:width" content={`${image.width}px`} />,
      <meta key={4} property="og:image:height" content={`${image.height}px`} />,
    ];
  }

  return [];
}

const formatKeywords = (keywords: string = "") => {
  return keywords
    .split(",")
    .map((keyword) => keyword.trim())
    .join(", ");
};

export default function SiteMeta({
  image,
  title,
  description,
  keywords,
  favicon,
}: SiteMetaProps) {
  const match = useRouteMatch();
  const { siteSettings } = useSiteSettings();

  return (
    <Helmet key={Math.random()}>
      <title>
        {getTitleProp(title, siteSettings.seoTitle || siteSettings.siteTitle)}
      </title>
      <meta
        property="og:title"
        content={getTitleProp(
          title,
          siteSettings.seoTitle || siteSettings.siteTitle
        )}
      />
      {description && (
        <meta name="description" content={stripHtml(description)} />
      )}
      {keywords && <meta name="keywords" content={formatKeywords(keywords)} />}
      {renderMetaImage(image, siteSettings.siteUrl)}
      <meta name="robots" content="index, follow" />
      <link
        rel="canonical"
        href={getBaseUrl(match.url, siteSettings.siteUrl)}
      />
    </Helmet>
  );
}
