import { useMemo, type Dispatch, type SetStateAction } from "react";
import { useAppData } from "../providers/AppDataProvider";
import { SiteSettingsDto } from "../dtos";

type SetSiteSettingsFn = (siteSettings: SiteSettingsDto) => void;

type UseSiteSettingsReturnType = {
  siteSettings: SiteSettingsDto;
  setSiteSettings: Dispatch<SetStateAction<SiteSettingsDto>>;
};

export const useSiteSettings = () => {
  const { appData, setAppData } = useAppData();

  return useMemo<UseSiteSettingsReturnType>(() => {
    const siteSettings: SiteSettingsDto = appData.siteSettings || {};
    const setSiteSettings = (
      nextSettings: SiteSettingsDto | SetSiteSettingsFn
    ) => {
      const nextSiteSettings =
        typeof nextSettings === "function"
          ? nextSettings(siteSettings)
          : nextSettings;
      setAppData((prev) => ({ ...prev, siteSettings: nextSiteSettings }));
    };
    return { siteSettings, setSiteSettings };
  }, [appData.siteSettings]);
};
