import { ImagePlugin } from './ImagePlugin';
import { ImageEditor } from './ImageEditor';
import { PluginConfig } from '../types';

const ImagePluginConfig: PluginConfig = {
	name: "Image",
	Component: ImagePlugin,
	Editor: ImageEditor,
}

export default ImagePluginConfig;