import { CardPlugin } from "./CardPlugin";
import { PluginConfig } from "../types";

const CardPluginConfig: PluginConfig = {
  name: "Card",
  Component: CardPlugin,
  allowsChildren: true,
  // Editor: CardEditor,
};

export default CardPluginConfig;
