import classNames from "clsx";
import type { ChangeEvent, FocusEvent, KeyboardEvent } from "react";
import React from "react";
import useToggle from "../../hooks/useToggle";
import { Button, IconButton, TextareaAutosize, TextField } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
import "./EditableName.scss";

type EditableNameProps = {
  label: string;
  setLabel: (label: string) => void;
  tooltipTitle?: string;
  className?: string;
  isGhost?: boolean;
  isEditingAllowed?: boolean;
  isMultiline?: boolean;
};

const getBtnClassname = (btnType: string) =>
  classNames("btn-toggle", `btn-${btnType}`);

const BTN_CLASSNAMES = {
  EDIT: getBtnClassname("edit"),
  CONFIRM: getBtnClassname("confirm"),
  CANCEL: getBtnClassname("cancel"),
};

const EditableName: React.FC<EditableNameProps> = ({
  label,
  setLabel,
  className,
  tooltipTitle,
  isEditingAllowed = true,
  isMultiline = false,
  isGhost,
}) => {
  const [isEditMode, toggleEditMode] = useToggle(false);

  const btnVariant = isGhost ? "ghost" : undefined;

  const handleInputBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { currentTarget, relatedTarget } = e;
    if (relatedTarget?.getAttribute("type") === "reset") {
      toggleEditMode();
      return;
    }
    if (currentTarget.value) {
      setLabel(currentTarget.value);
    }
    toggleEditMode();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!isMultiline) {
      (e.currentTarget as HTMLInputElement).size = e.currentTarget.value.length;
    }
  };

  const renderEditToggle = () => {
    return isEditingAllowed ? (
      <IconButton
        size="small"
        onClick={toggleEditMode}
        className={BTN_CLASSNAMES.EDIT}
      >
        <Edit />
      </IconButton>
    ) : null;
  };

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.currentTarget.setSelectionRange(
      e.currentTarget.value?.length || 0,
      e.currentTarget.value?.length || 0
    );
  };

  const renderInputField = () => {
    if (isMultiline) {
      return (
        <TextareaAutosize
          defaultValue={label}
          onBlur={handleInputBlur}
          onFocus={handleFocus}
          autoFocus
          className="editable-name-input textarea-input"
          onChange={onChange}
        />
      );
    }

    return (
      <input
        autoFocus
        defaultValue={label}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
        onChange={onChange}
        size={label.length}
        className="editable-name-input"
      />
    );
  };

  return (
    <span className={classNames("editable-name", className)}>
      {isEditMode && isEditingAllowed ? renderInputField() : label}
      {isEditMode && isEditingAllowed ? (
        <>
          <IconButton size="small" className={BTN_CLASSNAMES.CONFIRM}>
            <Check />
          </IconButton>
          <IconButton
            size="small"
            className={BTN_CLASSNAMES.CANCEL}
            type="reset"
          >
            <Close />
          </IconButton>
        </>
      ) : (
        renderEditToggle()
      )}
    </span>
  );
};

EditableName.displayName = "EditableName";

export default EditableName;
