import React, { useState, useEffect, MouseEvent, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  IconButton,
  useTheme,
  useMediaQuery,
  ButtonProps,
} from "@mui/material";
import {
  ContentCopy as CopyIcon,
  Email as EmailIcon,
  Share as ShareIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useIsClient } from "../../hooks/useIsClient";
import { useAppData } from "../../providers/AppDataProvider";

type ShareOption = "copy" | "email";

interface ShareOptionConfig {
  id: ShareOption;
  label: string;
  icon: React.ReactElement;
  onClick: () => void;
}

interface WebShareProps extends Omit<ButtonProps, "onClick" | "onError"> {
  title?: string;
  text?: string;
  url?: string;
  children?: React.ReactNode;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
  onClose?: () => void;
  fallbackOptions?: ShareOption[];
}

const DEFAULT_SHARE_DATA = {
  title: "",
  text: "",
  url: "",
};

export const WebShareButton: React.FC<WebShareProps> = ({
  title = DEFAULT_SHARE_DATA.title,
  text = DEFAULT_SHARE_DATA.text,
  url = DEFAULT_SHARE_DATA.url,
  children,
  onSuccess,
  onError,
  onClose,
  fallbackOptions = ["copy", "email"],
  variant = "contained",
  color = "primary",
  size = "medium",
  ...buttonProps
}) => {
  const {
    appData: { isMobile },
  } = useAppData();
  const isClient = useIsClient();
  const [canShare, setCanShare] = useState<boolean>(false);
  const [showFallback, setShowFallback] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const sharableURL = useMemo(() => {
    return isClient ? new URL(url, window.location.origin).toString() : url;
  }, [url, isClient]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // Check if Web Share API is available
    setCanShare(!!navigator.share && isMobile);
  }, []);

  const handleShare = async (e: MouseEvent<HTMLElement>): Promise<void> => {
    e.stopPropagation();
    try {
      if (canShare) {
        await navigator.share({
          title,
          text,
          url: sharableURL,
        });
        onSuccess?.();
      } else {
        setShowFallback(true);
      }
    } catch (error) {
      // Ignore AbortError which occurs when user cancels share dialog
      if (error instanceof Error && error.name !== "AbortError") {
        console.error("Error sharing:", error);
        setShowFallback(true);
        onError?.(error);
      }
    }
  };

  const handleCopy = async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(sharableURL);
      setShowFallback(false);
      setSnackbarMessage("Link copied to clipboard");
      setShowSnackbar(true);
      onSuccess?.();
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error copying:", error);
        setSnackbarMessage("Failed to copy link");
        setShowSnackbar(true);
        onError?.(error);
      }
    }
  };

  const handleEmail = (): void => {
    const subject = encodeURIComponent(title);
    const body = encodeURIComponent(`${text}\n\n${sharableURL}`);
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
    setShowFallback(false);
    onSuccess?.();
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === "clickaway") return;
    setShowSnackbar(false);
    onClose?.();
  };

  const shareOptions: ShareOptionConfig[] = [
    {
      id: "copy",
      label: "Copy Link",
      icon: <CopyIcon />,
      onClick: handleCopy,
    },
    {
      id: "email",
      label: "Email",
      icon: <EmailIcon />,
      onClick: handleEmail,
    },
  ].filter((option) => fallbackOptions.includes(option.id as any)) as any;

  return (
    <>
      {children ? (
        <div onClick={handleShare} style={{ cursor: "pointer" }}>
          {children}
        </div>
      ) : (
        <Button
          variant={variant}
          color={color}
          size={size}
          startIcon={<ShareIcon />}
          onClick={handleShare}
          {...buttonProps}
        >
          Share
        </Button>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={showFallback}
        onClose={() => setShowFallback(false)}
        PaperProps={{
          onClick: (event) => event.stopPropagation(),
          sx: {
            borderRadius: fullScreen ? 0 : 2,
            width: fullScreen ? "100%" : "auto",
            maxWidth: "100%",
            position: fullScreen ? "fixed" : "relative",
            bottom: fullScreen ? 0 : "auto",
            m: fullScreen ? 0 : 2,
          },
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          Share via
          <IconButton
            aria-label="close"
            onClick={() => setShowFallback(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <List sx={{ pt: 0 }}>
            {shareOptions.map((option) => (
              <ListItem button onClick={option.onClick} key={option.id}>
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText primary={option.label} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton
            size="small"
            color="inherit"
            onClick={() => setShowSnackbar(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};
