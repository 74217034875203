import React, {
  createContext,
  useContext,
  FC,
  useState,
  useCallback,
  useEffect,
} from "react";
import { API } from "../client/API";

const UserContext = createContext<any>({});

type Props = {
  user: any;
};

export const UserProvider: FC<Props> = ({ user: passedUser, children }) => {
  const [user, setUser] = useState<any>(passedUser);

  const logout = useCallback(() => {
    API.get("/logout").then(() => setUser(null));
  }, []);

  useEffect(() => {
    const handleLogin = (e: CustomEvent) => {
      setUser(e.detail);
    };
    const handleUpdate = (e: CustomEvent) => {
      if (user._id && e.detail._id === user?._id) {
        setUser(e.detail);
      }
    };
    window.addEventListener("USER_LOGGED_IN", handleLogin, false);
    window.addEventListener("USER_UPDATED", handleUpdate, false);

    return () => {
      window.removeEventListener("USER_LOGGED_IN", handleLogin, false);
      window.removeEventListener("USER_UPDATED", handleUpdate, false);
    };
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  return useContext(UserContext);
}
