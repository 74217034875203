import { useHistory } from "react-router-dom";
import { useCallback, useMemo } from "react";
import qs from "qs";

export const useDotySearchParams = () => {
  const history = useHistory();

  const searchParams = useMemo<Record<string, any>>(() => {
    return qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
  }, [history.location.search]);

  const setParam = useCallback(
    (key: string, value: any) => {
      const params = { ...searchParams, [key]: value };
      history.push({
        search: qs.stringify(params),
      });
    },
    [searchParams]
  );

  return {
    setParam,
    searchParams,
  };
};
