import { useMemo } from "react";
import { USER_ROLES, UserDto } from "../dtos";
import { useUser } from "../providers/UserProvider";

const getUserRole = (user: UserDto): USER_ROLES => {
  return user?.role?.type as USER_ROLES;
};

export const useIsEditingAllowed = (
  passedUser: UserDto | string | null,
  userRoles: USER_ROLES[] = [USER_ROLES.USER]
): boolean => {
  const { user } = useUser();

  const userRole = getUserRole(user);

  const isUserTheUser = useMemo<boolean>(() => {
    if (!passedUser) {
      return false;
    }
    if (typeof passedUser === "string") {
      return user && user?._id === passedUser;
    }
    if (typeof passedUser === "object") {
      return user && user?._id === passedUser._id;
    }
    return false;
  }, [user, passedUser]);

  const isUserAllowed =
    userRole === USER_ROLES.EDITOR ||
    userRole === USER_ROLES.ADMIN ||
    isUserTheUser ||
    userRoles.includes(userRole);

  return isUserAllowed;
};
