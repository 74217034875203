import React, { useState } from "react";
import { Box, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { API } from "../../client/API";
import { DotyCandidatesDto } from "../../dtos";
import DeleteForever from "@mui/icons-material/DeleteForever";
import AddAPhoto from "@mui/icons-material/AddAPhoto";
import Edit from "@mui/icons-material/Edit";
import useToggle from "../../hooks/useToggle";
import { Dialog } from "../../components/CustomDialog/CustomDialog";
import { ProtectedFormDialog } from "../FormDialog/FormDialog";

type Props = {
  doty: DotyCandidatesDto;
};

const DeleteDotyButton: React.FC<Props> = ({ doty }) => {
  const handleDelete = async () => {
    await API.delete(`/dotycandidates/${doty._id}`);
    window.dispatchEvent(new CustomEvent("DOTY_SAVED"));
  };

  return (
    <IconButton onClick={handleDelete}>
      <DeleteForever />
    </IconButton>
  );
};

const AddPhotoButton: React.FC<Props> = ({ doty }) => {
  return (
    <IconButton>
      <AddAPhoto />
    </IconButton>
  );
};

const EditDotyButton: React.FC<Props> = ({ doty }) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <>
      <IconButton onClick={toggleIsOpen}>
        <Edit />
      </IconButton>
      <Dialog isOpen={isOpen} onClose={toggleIsOpen}>
        <Dialog.Header>Edit {doty.fullName}</Dialog.Header>
        <Dialog.Content>
          <ProtectedFormDialog
            isProtected
            formSlug="dotycandidates"
            initialValues={doty}
            handleSuccess={toggleIsOpen}
          />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

const DotyCardActions: React.FC<Props> = ({ doty }) => {
  return (
    <Box display="flex" gap={1}>
      <EditDotyButton doty={doty} />
      <DeleteDotyButton doty={doty} />
    </Box>
  );
};

export default DotyCardActions;
