import React from "react";
import loadable from "@loadable/component";
import { ProtectedComponent } from "../../components/ProtectedComponent/ProtectedComponent";
import { USER_ROLES } from "../../dtos";

const Galleries = loadable(() => import("./Galleries"));

export const GalleriesPage = () => {
  return (
    <ProtectedComponent
      isRegisterEnabled
      isLoginEnabled
      roles={[USER_ROLES.USER]}
    >
      <Galleries />
    </ProtectedComponent>
  );
};
