import React, { createContext, FC, useContext } from "react";

type RowContextProps = { isInRow: boolean } & Record<string, any>;

const RowContext = createContext<RowContextProps>({ isInRow: false });

export const RowProvider: FC<RowContextProps> = ({
  children,
  spacing,
  isInRow,
}) => {
  return (
    <RowContext.Provider value={{ isInRow, spacing }}>
      {children}
    </RowContext.Provider>
  );
};

export const useRowContext = () => {
  return useContext(RowContext);
};
