const generatedUIDs: Record<string, boolean> = {};

function generateUIDWithCollisionChecking() {
  while (true) {
    var uid = (
      "0000" + ((Math.random() * Math.pow(36, 4)) | 0).toString(36)
    ).slice(-4);
    if (!generatedUIDs.hasOwnProperty(uid)) {
      generatedUIDs[uid] = true;
      return uid;
    }
  }
}

export function generateId(base = "id", size = 6) {
  return `${base}-${generateUIDWithCollisionChecking().slice(0, size)}`;
}
