type BaseDTO = {
  _id: string;
};

export function getDtoMap<TDto extends BaseDTO = BaseDTO>(
  items: TDto[],
  primaryKey: keyof TDto = "_id"
): Record<string, TDto> {
  if (!Array.isArray(items)) {
    return items || {};
  }
  return items.reduce((prevMap: Record<string, TDto>, item) => {
    const key = item[primaryKey];
    if (key) {
      return { ...prevMap, [key as string]: item };
    }
    return prevMap;
  }, {});
}
