const getDiffString = (diff: number, abbrev: string, isFull = false) => {
  switch (abbrev) {
    case "s":
      return isFull
        ? diff > 1
          ? `${diff} seconds ago`
          : `${diff} second ago`
        : `${diff}s`;
    case "m":
      return isFull
        ? diff > 1
          ? `${diff} minutes ago`
          : `${diff} minute ago`
        : `${diff}m`;
    case "h":
      return isFull
        ? diff > 1
          ? `${diff} hours ago`
          : `${diff} hour ago`
        : `${diff}h`;
    case "d":
      return isFull
        ? diff > 1
          ? `${diff} days ago`
          : `${diff} day ago`
        : `${diff}d`;
    case "mo":
      return isFull
        ? diff > 1
          ? `${diff} months ago`
          : `${diff} month ago`
        : `${diff}mo`;
    case "yr":
      return isFull
        ? diff > 1
          ? `${diff} years ago`
          : `${diff} year ago`
        : `${diff}yr`;
    default:
    case "w":
      return isFull
        ? diff > 1
          ? `${diff} weeks ago`
          : `${diff} week ago`
        : `${diff}w`;
  }
};

export function getTimeAgo(d: string | Date | number, isFull: boolean = false) {
  const date = new Date(d).getTime();
  const now = new Date().getTime();
  const diffInSeconds = Math.floor((now - date) / 1000);

  if (diffInSeconds < 60) {
    return getDiffString(diffInSeconds, "s", isFull);
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return getDiffString(minutes, "m", isFull);
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return getDiffString(hours, "h", isFull);
  } else if (diffInSeconds < 2592000) {
    const days = Math.floor(diffInSeconds / 86400);
    return getDiffString(days, "d", isFull);
  } else if (diffInSeconds < 31536000) {
    const months = Math.floor(diffInSeconds / 2592000);
    return getDiffString(months, "mo", isFull);
  } else {
    const years = Math.floor(diffInSeconds / 31536000);
    return getDiffString(years, "yr", isFull);
  }
}
