import { DefaultPlugin } from './DefaultPlugin';
import { DefaultEditor } from './DefaultEditor';
import { PluginConfig } from '../types';

const DefaultPluginConfig: PluginConfig = {
	name: "Default",
	Component: DefaultPlugin,
	Editor: DefaultEditor,
}

export default DefaultPluginConfig;