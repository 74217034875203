import React from "react";
import { loadableReady } from "@loadable/component";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { UserProvider } from "./providers/UserProvider";
import { AppDataProvider } from "./providers/AppDataProvider";
import { CacheProvider } from "@emotion/react";
import { createEmotionCache } from "./utils/createEmotionCache";

const getWindowValue = (key: string) => {
  return window[key];
};

loadableReady().then(() => {
  hydrate(
    <CacheProvider value={createEmotionCache()}>
      <UserProvider user={getWindowValue("LOGGED_IN_USER")}>
        <AppDataProvider appData={getWindowValue("APP_DATA")}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppDataProvider>
      </UserProvider>
    </CacheProvider>,
    document.getElementById("root")
  );
  document.body.classList.remove("body-hidden");
});

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept();
}
