import React from "react";
import { WidgetDto } from "../types";
import { Card } from "@mui/material";

const getElevation = (elevation: string) => {
  if (!elevation) {
    return 1;
  }
  return parseInt(elevation);
};

export const CardPlugin: React.FC<WidgetDto> = ({
  children,
  data = {},
  sx,
}) => {
  const { elevation, variant } = data;
  return (
    <Card elevation={getElevation(elevation)} sx={sx} variant={variant}>
      {children}
    </Card>
  );
};
