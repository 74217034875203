import React, {
  createContext,
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { usePageEditor } from "../PageStore/PageEditorProvider";
import { PageElementDto } from "../../../dtos";

type Props = {
  mainRef?: MutableRefObject<HTMLDivElement>;
};

type Context = {
  element: PageElementDto | null;
} & Props;

const RouteContext = createContext<Context>({ mainRef: null, element: null });

const EDIT_ELEMENT_EVT = "EDIT_ELEMENT";

export const dispatchEditElementEvt = (elementId: string) => {
  window.dispatchEvent(
    new CustomEvent(EDIT_ELEMENT_EVT, { detail: elementId })
  );
};

const createHistoryListeners = (history: any) => {
  const handleEvt = (e: CustomEvent) => {
    history.push(`/element/${e.detail}`);
  };
  window.addEventListener(EDIT_ELEMENT_EVT, handleEvt, false);

  return () => {
    window.removeEventListener(EDIT_ELEMENT_EVT, handleEvt, false);
  };
};

export const RouteProvider: React.FC<Props> = ({ children, mainRef }) => {
  const history = useHistory();
  const { elementId } = useParams<Record<string, string>>();
  const { getElement } = usePageEditor();

  const element = useMemo(() => {
    return elementId ? getElement(elementId) : null;
  }, [elementId, getElement]);

  useEffect(() => {
    return createHistoryListeners(history);
  }, []);

  return (
    <RouteContext.Provider value={{ mainRef, element }}>
      {children}
    </RouteContext.Provider>
  );
};

export const useRouteContext = () => useContext(RouteContext);
