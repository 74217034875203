import React, { Suspense, useCallback, useState } from "react";
import loadable from "@loadable/component";
import { useIsAdmin } from "../../hooks/useIsAdmin";
import { PageElement } from "./PageElement/PageElement";
import { ReadOnlyProvider } from "./ReadOnlyContext/ReadOnlyContext";
import { PageCSS } from "./PageCSS/PageCSS";
import { usePageContext } from "./PagesProvider/PagesProvider";
import SiteMeta from "../../components/SiteMeta";
import { PagesDto } from "../../dtos";
import { Box, Container } from "@mui/material";

const PageEditor = loadable(() => import("./PageEditor"));

type Props = {
  page: PagesDto;
};

const PageComponent: React.FC<Props> = ({ page: initialPage }) => {
  const isAdmin = useIsAdmin();
  const [page, setPage] = useState<PagesDto>(initialPage);

  const { elements = [], keywords = "", showKeywords = false } = page;

  const renderKeywords = useCallback(() => {
    if (showKeywords) {
      return (
        <Container>
          <Box my={3} display="flex" gap={1.5} flexWrap="wrap">
            {keywords.split(",").map((keyword) => (
              <Box
                px={1}
                py={0.25}
                border="1px solid rgba(100,100,100,0.5)"
                borderRadius={1}
                key={keyword}
                fontSize="0.75rem"
                color="primary.main"
              >
                {keyword.trim()}{" "}
              </Box>
            ))}
          </Box>
        </Container>
      );
    }
  }, [showKeywords, keywords]);

  const pageMarkup = (
    <div id="PAGE">
      <PageCSS customCss={page.customCss} />
      <SiteMeta
        key={page._id}
        title={page.title}
        description={page.description}
        image={page.featuredImage}
        keywords={keywords}
      />
      {elements.map((element) => {
        return <PageElement key={element.id} element={element} />;
      })}
      {renderKeywords()}
    </div>
  );

  if (isAdmin) {
    return (
      <>
        <PageCSS customCss={page.customCss} />
        <Suspense fallback={pageMarkup}>
          <SiteMeta
            key={page._id}
            title={page.title}
            description={page.description}
            keywords={keywords}
          />
          <ReadOnlyProvider>
            <PageEditor key={page.slug} page={page} setPage={setPage} />
          </ReadOnlyProvider>
          {renderKeywords()}
        </Suspense>
      </>
    );
  }

  return pageMarkup;
};

export const Page: React.FC = () => {
  const { isLoading, error, page } = usePageContext();

  if (!page) {
    return null;
  }

  return <PageComponent key={page._id} page={page} />;
};

Page.displayName = "PageBuilderPage";
