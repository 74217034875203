import React from "react";
import { WidgetDto } from "../types";
import { Box, Grid } from "@mui/material";
import { DotyCard } from "./DotyCard";
import { DotySortDropdown } from "./components/DotySortDropdown/DotySortDropdown";
import { DotyListJsonLD } from "./components/DotyListJsonLD";
import { DotyListContext, useDotysProvider } from "./useDoty";

export const DotyListPlugin: React.FC<WidgetDto> = (props) => {
  const { data, initialData = [] } = props;
  const context = useDotysProvider(initialData);
  const { dotys } = context;

  return (
    <DotyListContext.Provider value={context}>
      <Grid container spacing={2}>
        <DotyListJsonLD dotys={dotys} />
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <DotySortDropdown />
          </Box>
        </Grid>
        {dotys.map((doty) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={doty._id}>
              <DotyCard doty={doty} />
            </Grid>
          );
        })}
      </Grid>
    </DotyListContext.Provider>
  );
};
