import { MouseEvent, useState } from "react";
import { DotyCandidatesDto } from "../../dtos";
import { API } from "../../client/API";
import { useAppData } from "../../providers/AppDataProvider";
import { getCookieValue } from "../../hooks/useCookie";

export const useDotyVote = ({
  doty,
  setDoty,
}: {
  doty: DotyCandidatesDto;
  setDoty: (doty: DotyCandidatesDto) => void;
}) => {
  const { setAppData } = useAppData();

  const handleVoteClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const inc = doty.hasVoted ? -1 : 1;
    const hasVoted = !!doty.hasVoted;
    setDoty({
      ...doty,
      votesCount: doty.votesCount + inc,
      hasVoted: !hasVoted,
    });
    API.post(`/dotycandidates/vote/${doty._id}`)
      .then((results) => {
        dispatchEvent(new CustomEvent("DOTY_SAVED", { detail: results }));
        setAppData((prevState) => ({
          ...prevState,
          voterId: getCookieValue("voterId", prevState.voterId),
        }));
        setDoty(results);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return handleVoteClick;
};
