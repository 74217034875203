import React from "react";
import loadable from "@loadable/component";
import { useIsClient } from "../../hooks/useIsClient";

const LazyFormBuilder = loadable(() => import("./FormBuilder"));

const FormBuilder = () => {
  const isClient = useIsClient();

  return isClient ? <LazyFormBuilder /> : null;
};

export default FormBuilder;
