import React from "react";
import { WidgetDto } from "../types";
import { RowProvider } from "../../pages/Page/PageRow/RowProvider";
import { Grid } from "@mui/material";

export const RowPlugin: React.FC<WidgetDto> = (props) => {
  const { spacing = 2, children, id, data = {} } = props;
  return (
    <RowProvider isInRow>
      <Grid container spacing={spacing} sx={data.sx}>
        {children}
      </Grid>
    </RowProvider>
  );
};
