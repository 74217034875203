import React, { Dispatch, SetStateAction, useMemo } from "react";
import { CommentsDto, USER_ROLES } from "../../../dtos";
import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import styles from "./CommentActions.module.scss";
import { CustomAvatar } from "../../Avatar/CustomAvatar";
import { ProtectedComponent } from "../../ProtectedComponent/ProtectedComponent";
import clsx from "clsx";
import { useCommentsContext } from "../CommentsProvider/CommentsProvider";
import { CommentsField } from "../../CommentsField/CommentsField";
import useToggle from "../../../hooks/useToggle";
import { getTimeAgo } from "../../../utils/getTimeAgo";
import { Close, Reply, ExpandLess, DeleteForever } from "@mui/icons-material";
import { useIsEditingAllowed } from "../../../hooks/useIsEditingAllowed";
import EditableName from "../../EditableName/EditableName";

type Props = {
  item: CommentsDto & { ancestorIds?: string[] };
  setComments: Dispatch<SetStateAction<CommentsDto[]>>;
  isMobile?: boolean;
  isOutlined?: boolean;
};

const focusRefAndSelect = (textareaEl: HTMLTextAreaElement) => {
  if (textareaEl) {
    textareaEl.focus();
    textareaEl.setSelectionRange(
      textareaEl.value.length,
      textareaEl.value.length
    );
  }
};

export const CommentItem: React.FC<Props> = ({
  item,
  setComments,
  isMobile,
  isOutlined,
}) => {
  const [isEditMode, setEditMode] = React.useState(false);
  const { comment, createdAt, user, ancestorIds = [], ts } = item;
  const [isReplyOpen, toggleIsReplyOpen] = useToggle(false);
  const { removeComment, updateComment } = useCommentsContext();
  const canEdit = useIsEditingAllowed(item.user, [
    USER_ROLES.ADMIN,
    USER_ROLES.EDITOR,
  ]);

  const timeAgo = useMemo(
    () => getTimeAgo(ts || new Date(createdAt).toLocaleDateString()),
    [ts, createdAt]
  );

  const handleEditComplete = async (editedComment: string) => {
    if (editedComment && editedComment !== comment) {
      updateComment({ ...item, comment: editedComment }, ancestorIds);
    }
  };

  const deleteComment = (commentId: string) => {
    removeComment(commentId, ancestorIds);
  };

  const renderComment = () => {
    return (
      <Box width="100%">
        <EditableName
          label={comment}
          setLabel={handleEditComplete}
          isEditingAllowed={canEdit}
          isMultiline
        />
      </Box>
    );
  };

  return (
    <>
      <Paper
        sx={{
          padding: isMobile || isOutlined ? 0 : 1.25,
          px: isOutlined ? 1.5 : undefined,
          ml: ancestorIds.length * 2,
          fontSize: "0.875rem",
          my: isMobile ? undefined : 1,
          background: isMobile ? "transparent" : undefined,
        }}
        variant={isOutlined ? "outlined" : "elevation"}
        elevation={isMobile || isOutlined ? 0 : 1}
      >
        <Box display="flex">
          <Box
            height={isMobile || isOutlined ? 60 : 75}
            px={isMobile ? 0.75 : 1}
            pl={0.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CustomAvatar
              profilePicture={user?.profilePicture}
              fullName={user?.fullName}
              sx={{
                width: isMobile || isOutlined ? 32 : 54,
                height: isMobile || isOutlined ? 32 : 54,
                fontSize: isMobile || isOutlined ? "1rem" : "1.25rem",
              }}
            />
          </Box>
          <Box maxWidth="calc(100% - 60px)" flex={1}>
            <Box sx={{ p: 1, width: "100%", overflow: "hidden" }}>
              <Box fontSize={12}>
                <Box component="span" fontWeight={600}>
                  {user.username}
                </Box>{" "}
                <Box component="span">{timeAgo}</Box>
              </Box>
              {renderComment()}
              {!isOutlined && (
                <Box ml={-0.75}>
                  <ProtectedComponent
                    isLoginEnabled={false}
                    isRegisterEnabled={false}
                    initialUser={user}
                    roles={[USER_ROLES.USER]}
                  >
                    <Button
                      color="inherit"
                      className={clsx(styles.btn, styles.inlineBtn)}
                      onClick={toggleIsReplyOpen}
                    >
                      Reply
                    </Button>
                  </ProtectedComponent>
                  <ProtectedComponent
                    isLoginEnabled={false}
                    isRegisterEnabled={false}
                    initialUser={user}
                    roles={[USER_ROLES.EDITOR]}
                  >
                    <Button
                      className={clsx(styles.btn, styles.inlineBtn)}
                      onClick={() => setEditMode(true)}
                    >
                      Edit
                    </Button>
                    <Button
                      color="error"
                      className={clsx(styles.btn, styles.inlineBtn)}
                      onClick={() => deleteComment(item._id)}
                    >
                      Delete
                    </Button>
                  </ProtectedComponent>
                </Box>
              )}
            </Box>
          </Box>
          {isOutlined && (
            <ProtectedComponent
              isLoginEnabled={false}
              isRegisterEnabled={false}
              initialUser={user}
              roles={[USER_ROLES.USER]}
            >
              <Box
                display="flex"
                alignSelf="flex-start"
                alignItems="center"
                height={60}
              >
                <Tooltip
                  placement="top"
                  arrow
                  title={`Reply to ${user.fullName}`}
                >
                  <IconButton
                    color="inherit"
                    sx={{
                      padding: 0.5,
                      border: "1px solid rgba(100,100,100,0)",
                      "&:hover": {
                        borderColor: "1px solid rgba(100,100,100,0)",
                      },
                    }}
                    className={clsx(styles.btn, styles.inlineBtn)}
                    onClick={toggleIsReplyOpen}
                  >
                    {!isReplyOpen ? (
                      <Reply sx={{ fontSize: 16 }} />
                    ) : (
                      <ExpandLess sx={{ fontSize: 16 }} />
                    )}
                  </IconButton>
                </Tooltip>
                <ProtectedComponent
                  isLoginEnabled={false}
                  isRegisterEnabled={false}
                  initialUser={user}
                  roles={[USER_ROLES.EDITOR]}
                >
                  <Tooltip placement="top" arrow title="Delete comment">
                    <IconButton
                      color="error"
                      className={clsx(styles.btn, styles.inlineBtn)}
                      onClick={() => deleteComment(item._id)}
                    >
                      <DeleteForever sx={{ fontSize: 16 }} />
                    </IconButton>
                  </Tooltip>
                </ProtectedComponent>
              </Box>
            </ProtectedComponent>
          )}
        </Box>
      </Paper>
      {isReplyOpen && (
        <Paper
          sx={{
            padding: 1.25,
            ml: 2,
          }}
        >
          <CommentsField
            isMobile={isMobile || isOutlined}
            parentComment={item}
            afterSubmit={toggleIsReplyOpen}
          />
        </Paper>
      )}
    </>
  );
};
