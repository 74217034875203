import { useMemo } from "react";
import { useDotySearchParams } from "./hooks/useDotySearchParams";

export const useDotyToggle = (id: string) => {
  const { searchParams, setParam } = useDotySearchParams();

  const isOpen = useMemo(() => {
    return searchParams?.cId === id;
  }, [searchParams?.cId, id]);

  const toggleIsOpen = () => {
    if (isOpen) {
      setParam("cId", undefined);
    } else {
      setParam("cId", id);
    }
  };

  return { isOpen, toggleIsOpen };
};
