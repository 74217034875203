import { useEffect, useState } from "react";
import { useAppData } from "../providers/AppDataProvider";

export const useIsMobile = () => {
  const { appData } = useAppData();
  const [isMobile, setIsMobile] = useState<boolean>(!!appData.isMobile);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };
    handleResize();
    window.addEventListener("resize", handleResize, false);

    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, []);

  return isMobile;
};
