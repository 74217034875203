import { FormDialogPlugin } from "./FormDialogPlugin";
import { FormDialogEditor } from "./FormDialogEditor";
import { PluginConfig } from "../types";

const FormDialogPluginConfig: PluginConfig = {
  name: "formdialog",
  Component: FormDialogPlugin,
  Editor: FormDialogEditor,
};

export default FormDialogPluginConfig;
