import { IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { DotyCandidatesDto, USER_ROLES } from "../../../../dtos";
import Edit from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import { ProtectedComponent } from "../../../../components/ProtectedComponent/ProtectedComponent";
import { FormDialog } from "../../../../components/FormDialog/FormDialog";
import useToggle from "../../../../hooks/useToggle";
import { DotyPhotoButton } from "../DotyPhotoButton";
import { useHistory } from "react-router-dom";
import { WebShareButton } from "../../../../components/WebShareButton/WebShareButton";

type Props = {
  doty: DotyCandidatesDto;
};

export const DotyContextMenu: React.FC<Props> = ({ doty }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isEditOpen, toggleEditOpen, openEditForm, closeEditForm] =
    useToggle(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSendClick = () => {
    history.push(`/doty/${doty._id}`);
  };

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ padding: 0.5 }}
      >
        <MoreHorizIcon sx={{ fontSize: 20 }} />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        sx={{
          ul: { py: 0 },
          ".MuiMenuItem-root": { fontSize: 14 },
          svg: { fontSize: 16 },
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <ProtectedComponent
          isLoginEnabled={false}
          isRegisterEnabled={false}
          initialUser={doty.createdBy}
          roles={[USER_ROLES.ADMIN, USER_ROLES.EDITOR]}
        >
          <MenuItem sx={{ display: "flex", gap: 1 }} onClick={openEditForm}>
            <Edit fontSize="small" /> Edit
          </MenuItem>
        </ProtectedComponent>
        <WebShareButton
          title={`Vote for ${doty.fullName}`}
          url={`/doty/${doty._id}`}
          text={`Please vote for ${doty.fullName} as the 2024 Dilf of the year!`}
          onClose={handleClose}
        >
          <MenuItem sx={{ display: "flex", gap: 1 }}>
            <SendIcon fontSize="small" /> Share
          </MenuItem>
        </WebShareButton>
        <DotyPhotoButton dotyId={doty._id} isMenuItem onSuccess={handleClose} />
      </Menu>
      <FormDialog
        isOpen={isEditOpen}
        onClose={closeEditForm}
        initialValues={doty}
        formSlug="dotycandidates"
      />
    </>
  );
};
