type BaseElement = {
  id: string | number;
};

type BaseModelDto = {
  _id: string;
};

type ConvertedModelDto<T extends BaseModelDto = BaseModelDto> = BaseElement & T;

type Convert<V, O extends object> = {
  [Key in keyof O]: V;
};

type FindProps<T> = T & { index: number; ancestorIds: Array<string | number> };

export const convertModelsToDtos = <T extends BaseModelDto = BaseModelDto>(
  models: Array<T>,
  recursiveField: keyof T
): ConvertedModelDto<T>[] => {
  return models.map((model) => {
    const { _id } = model;
    const childModels = model[recursiveField] as T[];

    if (childModels?.length) {
      return {
        ...model,
        id: _id,
        [recursiveField]: convertModelsToDtos(childModels, recursiveField),
      };
    }

    return {
      ...model,
      id: _id,
    };
  });
};

export const findElementDeep = <T extends BaseElement = BaseElement>(
  items: T[],
  selectedId: string,
  recursiveField: keyof T,
  ancestorIds: Array<string | number> = []
): FindProps<T> => {
  let count = 0;
  for (const item of items) {
    const { id } = item;
    const childItems = item[recursiveField] as T[];

    if (id === selectedId) {
      const foundItem = { ...item, ancestorIds, index: count };
      count = count + 1;
      return foundItem;
    }

    count = count + 1;

    if (childItems?.length) {
      const child = findElementDeep(childItems, selectedId, recursiveField, [
        ...ancestorIds,
        id,
      ]);

      if (child) {
        return child;
      }
    }
  }

  return undefined;
};

export const removeElementDeep = <T extends BaseElement = BaseElement>(
  items: T[],
  selectedId: string,
  recursiveField: keyof T
): T[] => {
  return items
    .filter((item) => item.id !== selectedId)
    .map((element) => {
      const childItems = element[recursiveField] as T[];
      return {
        ...element,
        [recursiveField]: childItems?.length
          ? removeElementDeep(childItems, selectedId, recursiveField)
          : [],
      };
    });
};

export const updateElementDeep = <T extends BaseElement = BaseElement>(
  updatedElement: T,
  elements: T[],
  recursiveField: keyof T
): T[] => {
  return elements.map((element) => {
    if (element.id === updatedElement.id) {
      return { ...element, ...updatedElement };
    }
    const childElements = element[recursiveField] as T[];
    if (childElements?.length) {
      return {
        ...element,
        [recursiveField]: updateElementDeep(
          updatedElement,
          childElements,
          recursiveField
        ),
      };
    }
    return element;
  });
};

export const addChildToElement = <T extends BaseElement = BaseElement>(
  parentId: string,
  newElement: T,
  elements: T[],
  recursiveField
): T[] => {
  return elements.map((element) => {
    const { id } = element;
    const childElements = element[recursiveField] as T[];

    if (id === parentId) {
      return {
        ...element,
        [recursiveField]: [...childElements, newElement],
      };
    }

    if (!childElements.length) {
      return element;
    }

    return {
      ...element,
      [recursiveField]: addChildToElement(
        parentId,
        newElement,
        childElements,
        recursiveField
      ),
    };
  });
};

export function flattenElements<T extends BaseElement = BaseElement>(
  items: T[],
  recursiveField: keyof T,
  parentId: string | null = null,
  depth = 0,
  ancestorIds = []
): any[] {
  if (!items?.length) {
    return [];
  }
  return items.reduce<any[]>((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index, ancestorIds },
      ...flattenElements(
        (item[recursiveField] as T[]) || [],
        recursiveField,
        item.id as any,
        depth + 1,
        [...ancestorIds, item.id]
      ),
    ];
  }, []);
}
